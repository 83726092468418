import type { MouseEventHandler } from "react";
import { useState } from "react";

import type { Interpolation, Theme } from "@emotion/react";
import { css } from "@emotion/react";
import { Typography, colors } from "@PRNDcompany/heydealer-ui";
import ClipLoader from "react-spinners/ClipLoader";

import SortableTh from "./SortableTh";
import type { HeaderKey } from "./types";

export type SortableTableProps<TData extends Record<string, unknown>> = {
  headerKeys: HeaderKey<TData>[];
  data?: TData[];
  loading?: boolean;
  defaultSort?: { key: keyof TData; ascending?: boolean };
  fixedColumnKey?: keyof TData | (keyof TData)[];
  getCustomCellStyle?: (datum: TData, key: keyof TData) => Interpolation<Theme>;
  getIsCellValueClickable?: (datum: TData, key: keyof TData) => boolean;
  onCellValueClick?: (datum: TData, key: keyof TData) => void;
};

const tableStyle = css`
  position: relative;

  width: 100%;

  border-spacing: 0;
`;

const cellStyle = css`
  padding: 0.5rem 1rem;

  background: ${colors.base_white};

  border-bottom: 1px solid ${colors.base_gray4};
  border-right: 1px solid ${colors.base_gray4};

  &:first-child {
    border-left: 1px solid ${colors.base_gray4};
  }

  text-align: center;
  word-break: keep-all;

  white-space: pre;
`;

const fixedCellStyle = css`
  position: sticky;
  left: 0;
  right: 0;

  z-index: 1;
`;

const clickableCellValueStyle = css`
  text-decoration: underline dotted;
  cursor: pointer;

  @media (hover: hover) {
    :hover {
      color: ${colors.base_gray5};
    }
  }
`;

const DEFAULT_ASCENDING = false;

const SortableTable = <TData extends Record<string, unknown>>({
  headerKeys,
  data,
  loading,
  defaultSort,
  fixedColumnKey,
  getCustomCellStyle,
  getIsCellValueClickable,
  onCellValueClick,
}: SortableTableProps<TData>) => {
  const [sortKey, setSortKey] = useState<keyof TData>(defaultSort?.key || headerKeys[0].key);
  const [isAscending, setAscending] = useState(defaultSort?.ascending ?? DEFAULT_ASCENDING);

  const getIsFixed = (key: keyof TData) =>
    Array.isArray(fixedColumnKey) ? fixedColumnKey.includes(key) : fixedColumnKey === key;

  const onThClick: MouseEventHandler<HTMLTableCellElement> = (e) => {
    const { key } = e.currentTarget.dataset;
    if (!key) {
      return;
    }

    if (key === sortKey) {
      setAscending(!isAscending);
    } else {
      setAscending(DEFAULT_ASCENDING);
      setSortKey(key);
    }
  };

  return (
    <div css={{ display: "flex", flexDirection: "column", isolation: "isolate" }}>
      <table css={tableStyle}>
        <thead>
          <tr css={{ position: "sticky", top: 0, zIndex: 2 }}>
            {headerKeys.map(({ key, label }) => (
              <SortableTh
                key={`header_${key.toString()}`}
                dataKey={key}
                header={label}
                selected={sortKey === key}
                ascending={isAscending}
                cellStyle={cellStyle}
                fixed={getIsFixed(key)}
                onClick={onThClick}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {data
            ?.sort(({ [sortKey]: a }, { [sortKey]: b }) =>
              a === null ? 1 : b === null ? -1 : (a > b ? 1 : -1) * (isAscending ? 1 : -1)
            )
            .map((row, i) => (
              <tr key={`row_${i}`}>
                {headerKeys.map(({ key, formatter }) => (
                  <td
                    key={`row_${i}_${key.toString()}`}
                    css={[cellStyle, getIsFixed(key) && fixedCellStyle, getCustomCellStyle?.(row, key)]}
                  >
                    <div
                      css={getIsCellValueClickable?.(row, key) && clickableCellValueStyle}
                      onClick={() => onCellValueClick?.(row, key)}
                    >
                      <Typography variant="body_2">{String(formatter?.(row[key]) ?? row[key] ?? "")}</Typography>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      {loading ? (
        <div css={{ margin: "1rem auto" }}>
          <ClipLoader loading color={colors.brand_primary} />
        </div>
      ) : (
        !data?.length && (
          <div css={{ textAlign: "center", padding: "1rem" }}>
            <Typography variant="body_1">데이터가 없습니다.</Typography>
          </div>
        )
      )}
    </div>
  );
};

export default SortableTable;
