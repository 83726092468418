import type { ChangeEventHandler, FormEventHandler, VFC } from "react";
import { useState } from "react";

import { css } from "@emotion/react";
import { Button, SinglelineTextfield } from "@PRNDcompany/heydealer-ui";
import taxios from "@PRNDcompany/taxios";
import { useMutation } from "react-query";

import { setAuthToken } from "src/modules/auth";

const containerStyle = css`
  display: grid;
  gap: 0.5rem;

  width: 15rem;
`;

const baseURL = process.env.REACT_APP_BASE_URL;

const LoginForm: VFC = () => {
  if (!baseURL) {
    throw new Error("REACT_APP_BASE_URL is not defined");
  }

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { mutate, isLoading } = useMutation<{ token: string }, { username: string; password: string }>(
    "login",
    async () => (await taxios.post("/login/", { username, password }, { baseURL: new URL(baseURL).origin })).data,
    { onSuccess: ({ token }) => setAuthToken(token) }
  );

  const onUserNameChange: ChangeEventHandler<HTMLInputElement> = (e) => setUsername(e.currentTarget.value);
  const onPasswordChange: ChangeEventHandler<HTMLInputElement> = (e) => setPassword(e.currentTarget.value);
  const onFormSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    mutate();
  };

  return (
    <form css={containerStyle} onSubmit={onFormSubmit}>
      <SinglelineTextfield size={56} value={username} onChange={onUserNameChange} placeholder="아이디" />
      <SinglelineTextfield
        size={56}
        value={password}
        type="password"
        onChange={onPasswordChange}
        placeholder="비밀번호"
      />
      <Button fullWidth loading={isLoading}>
        로그인
      </Button>
    </form>
  );
};

export default LoginForm;
