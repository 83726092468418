import type { FC } from "react";

import { css } from "@emotion/react";
import { Button, HeydealerLogo, colors } from "@PRNDcompany/heydealer-ui";
import { Link } from "react-router-dom";

const headerStyle = css`
  background: ${colors.base_primary};
`;

const headerContentStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  box-sizing: border-box;
  height: 4rem;

  margin: 0 auto;

  padding-right: 1rem;
`;

const logoWrapperStyle = css`
  width: auto;
  height: 1.25rem;

  padding: 1.25rem;

  color: ${colors.base_white};

  cursor: pointer;
`;

const Header: FC = () => {
  const onClickLogOut = () => {
    localStorage.removeItem("token");
    location.reload();
  };

  return (
    <header css={headerStyle}>
      <div css={headerContentStyle}>
        <Link css={logoWrapperStyle} to="/">
          <HeydealerLogo />
        </Link>
        <Button variant="text" size={56} color="white" onClick={onClickLogOut}>
          로그아웃
        </Button>
      </div>
    </header>
  );
};

export default Header;
