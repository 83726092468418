import type { FC } from "react";
import React from "react";

import { Dropdown } from "@PRNDcompany/heydealer-ui";

type StatusDropdownProps = {
  value: string;
  onChange: (value: string) => void;
};

const options = [
  { value: "", children: "전체" },
  { value: "active", children: "활동" },
  { value: "inactive", children: "활동중지" },
];

const StatusDropdown: FC<StatusDropdownProps> = ({ value, onChange }) => {
  return <Dropdown width={200} value={value} onChange={onChange} size={48} options={options} />;
};

export default StatusDropdown;
