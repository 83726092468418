import type { VFC } from "react";

import { Divider } from "@PRNDcompany/heydealer-ui";

import type { GetAccidentRepairsImageResponse } from "src/apis";
import type { Car } from "src/apis/cars/types";
import Paper from "src/components/atoms/Paper";
import Carousel from "src/components/molecules/Carousel";

import AccidentRepair from "./AccidentRepair";
import AccidentRepairDiff from "./AccidentRepairDiff";
import CarCondition from "./CarCondition";
import CarListItemHeader from "./CarListItemHeader";
import CarSpec from "./CarSpec";
import DataListItem from "./DataListItem";
import PaintedExplanation from "./PaintedExplanation";

type CarListItemProps = {
  car: Car;
  accidentRepairsImage?: GetAccidentRepairsImageResponse;
};

const CarListItem: VFC<CarListItemProps> = ({ car, accidentRepairsImage }) => {
  const { condition_data, inspected_condition, inspection_questionnaire_description, accident_description } =
    car.detail;

  return (
    <Paper>
      <div css={{ display: "grid", gap: "1rem", padding: "1.125rem" }}>
        <CarListItemHeader car={car} />
        <Divider />
        <div css={{ display: "grid", gridTemplateColumns: "60rem auto", justifyContent: "start", gap: "1.5rem" }}>
          <div css={{ display: "grid", gap: "1.5rem", gridTemplateColumns: "auto 1fr" }}>
            <div css={{ display: "grid", gap: "1.5rem" }}>
              <Paper>
                <div css={{ padding: "1.125rem" }}>
                  <Carousel imageURLs={car.detail.image_urls} />
                </div>
              </Paper>
              {accidentRepairsImage && (
                <Paper>
                  <div css={{ padding: "1.125rem", display: "grid", gap: "1.25rem" }}>
                    <AccidentRepair accidentRepairsImage={accidentRepairsImage} car={car} />
                    {car.detail.accident_repairs.some(({ repair }) => repair === "painted") && <PaintedExplanation />}
                  </div>
                </Paper>
              )}
            </div>
            <Paper>
              <div css={{ display: "grid", gap: "3rem", padding: "1.125rem" }}>
                <CarSpec car={car} />
                {condition_data && <CarCondition conditionData={condition_data} />}
                <Divider />
                <div css={{ display: "grid", gap: "1rem" }}>
                  {inspected_condition && <DataListItem label="평가사 기타의견" value={inspected_condition.comment} />}
                  {inspection_questionnaire_description && (
                    <DataListItem label="고객 차량설명" value={inspection_questionnaire_description} />
                  )}
                  {accident_description && <DataListItem label="사고 특이사항" value={accident_description} />}
                </div>
              </div>
            </Paper>
          </div>
          <AccidentRepairDiff car={car} />
        </div>
      </div>
    </Paper>
  );
};

export default CarListItem;
