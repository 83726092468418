import type { FC } from "react";

import { css } from "@emotion/react";
import { Button, SearchIcon, Typography } from "@PRNDcompany/heydealer-ui";
import type { SubmitHandler } from "react-hook-form";
import { FormProvider, useController, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import type { GetInspectorsStatsParams } from "src/apis";

import CompanyDropdown from "../../../components/CompanyDropdown";
import StatusDropdown from "../../../components/StatusDropdown";
import { useInspectorsStatsParams } from "../../hooks/useInspectorsStatsParams";

import RangePicker from "./RangePicker";

export type FilterGroupFormValues = {
  [k in keyof GetInspectorsStatsParams]-?:
    | GetInspectorsStatsParams[k]
    | (undefined extends GetInspectorsStatsParams[k] ? null : never);
};

const wrapperStyle = css`
  display: grid;
  gap: 1rem;
`;

const rowStyle = css`
  display: grid;
  gap: 0.5rem;
`;

type FilterGroupProps = {
  onSearch: () => void;
};

const FilterGroup: FC<FilterGroupProps> = ({ onSearch }) => {
  const inspectorsStatsParams = useInspectorsStatsParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const methods = useForm<FilterGroupFormValues>({ defaultValues: inspectorsStatsParams });
  const { handleSubmit: createHandleSubmit, control } = methods;

  const { value: companyId, onChange: handleCompanyIdChange } = useController({ control, name: "company_id" }).field;
  const { value: status, onChange: handleStatusChange } = useController({ control, name: "status" }).field;

  const handleValidSubmit: SubmitHandler<FilterGroupFormValues> = (data) => {
    const params = new URLSearchParams(searchParams);

    Object.entries(data).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        params.set(key, String(value));
      } else {
        params.delete(key);
      }
    });

    setSearchParams(params);
    onSearch();
  };

  return (
    <FormProvider {...methods}>
      <div css={wrapperStyle}>
        <div css={rowStyle}>
          <Typography variant="subtitle_3">범위</Typography>
          <RangePicker />
        </div>
        <div css={{ display: "flex", gap: "1rem" }}>
          <div css={rowStyle}>
            <Typography variant="subtitle_3">소속업체</Typography>
            <CompanyDropdown value={companyId || ""} onChange={handleCompanyIdChange} />
          </div>
          <div css={rowStyle}>
            <Typography variant="subtitle_3">상태</Typography>
            <StatusDropdown value={status || ""} onChange={handleStatusChange} />
          </div>
        </div>
        <div>
          <Button variant="primary" size={48} icon={SearchIcon} onClick={createHandleSubmit(handleValidSubmit)}>
            검색
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};

export default FilterGroup;
