import type { FC } from "react";
import React from "react";

import { useAccordionContext } from "src/components/Accordion";

import CommentList from "./CommentList";
import type { CommentListProps } from "./CommentList";

export type CommentListAccordionContentProps = CommentListProps;

const CommentListAccordionContent: FC<CommentListAccordionContentProps> = (props) => {
  const { isOpen } = useAccordionContext();

  if (!isOpen) {
    return null;
  }

  return <CommentList {...props} />;
};

export default CommentListAccordionContent;
