import type { VFC } from "react";

import { css } from "@emotion/react";

import type { GetAccidentRepairsImageResponse } from "src/apis";
import type { Coordinate } from "src/apis/accident_repairs_image/types";
import type { AccidentRepair as AccidentRepairType, Car, Repair } from "src/apis/cars/types";
import Exchange from "src/assets/accident-repair-item-exchange.svg";
import Painted from "src/assets/accident-repair-item-painted.svg";
import Weld from "src/assets/accident-repair-item-weld.svg";

type AccidentRepairProps = {
  car: Car;
  accidentRepairsImage: GetAccidentRepairsImageResponse;
};

type AccidentRepairWithCoordinate = Pick<AccidentRepairType, "repair"> & Coordinate;

const accidentRepairImageWrapperStyle = css`
  position: relative;

  width: 28.125rem;
`;

const repairIconMap: Record<Repair, string> = {
  weld: Weld,
  exchange: Exchange,
  painted: Painted,
};

const getAccidentRepairStyle = (x: number, y: number) => css`
  position: absolute;
  top: ${y / 16 - 0.75}rem;
  left: ${x / 16 - 0.75}rem;

  width: 1.5rem;
  height: 1.5rem;

  border-radius: 50%;

  box-shadow: 0 1px 2px 0 rgba(39, 46, 64, 0.3);
`;

const AccidentRepair: VFC<AccidentRepairProps> = ({ accidentRepairsImage, car }) => {
  const { accident_repairs } = car.detail;

  const accidentRepairWithCoordinates: AccidentRepairWithCoordinate[] = [];
  accident_repairs.forEach((accidentRepair) => {
    const coordinate = accidentRepairsImage.coordinates.find((coordinate) => coordinate.part === accidentRepair.part);
    if (coordinate) {
      accidentRepairWithCoordinates.push({ ...accidentRepair, x: coordinate.x, y: coordinate.y });
    }
  });

  const accidentRepairImageStyle = css`
    display: block;

    width: ${accidentRepairsImage.image_width / 16}rem;
  `;

  return (
    <div css={accidentRepairImageWrapperStyle}>
      <img src={accidentRepairsImage.image_url} css={accidentRepairImageStyle} />
      {accidentRepairWithCoordinates.map(({ part, x, y, repair }) => (
        <img src={repairIconMap[repair]} key={part} css={[getAccidentRepairStyle(x, y)]} alt={part} />
      ))}
    </div>
  );
};

export default AccidentRepair;
