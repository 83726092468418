import { useQuery } from "react-query";

import { createGetInspectorCommentsAPI } from "src/apis";
import type { InspectorComment } from "src/apis/types";
import { queryKeys } from "src/constants/queryKeys";

const useInspectorCommentsQuery = (hashId: string, initialData: InspectorComment[]) => {
  const { data, ...rest } = useQuery({
    queryKey: queryKeys.inspectors.byHashId.comments(hashId),
    queryFn: createGetInspectorCommentsAPI(hashId),
    staleTime: Infinity,
    initialData,
  });

  if (!data) {
    throw new Error("data is required");
  }

  return { data, ...rest };
};

export default useInspectorCommentsQuery;
