import type { ChangeEventHandler, FC, FormEventHandler } from "react";
import React from "react";

import { css } from "@emotion/react";
import { Button, SinglelineTextfield } from "@PRNDcompany/heydealer-ui";

type CommentFormProps = {
  content: string;
  onContentChange: ChangeEventHandler;
  onSubmit: FormEventHandler<HTMLFormElement>;
  loading?: boolean;
};

const wrapperStyle = css`
  display: grid;
  align-items: center;
  gap: 0.5rem;

  grid-template-columns: 1fr auto;
`;

const CommentForm: FC<CommentFormProps> = ({ content, onContentChange, onSubmit, loading }) => {
  return (
    <form css={wrapperStyle} onSubmit={onSubmit}>
      <SinglelineTextfield size={48} placeholder="내용 입력" value={content} onChange={onContentChange} />
      <Button variant="primary" color="navy" size={48} type="submit" loading={loading}>
        입력
      </Button>
    </form>
  );
};

export default CommentForm;
