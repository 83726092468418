import type { FC } from "react";

import { css } from "@emotion/react";
import { EtcDeleteCircleIcon, Typography, toast, IconButton } from "@PRNDcompany/heydealer-ui";
import dayjs from "dayjs";
import { useMutation } from "react-query";

import { createDeletePreInspectionScheduleRulesExclusionAPI } from "src/apis";
import type { ExclusionRule as ExclusionRuleType } from "src/apis/types";

type ExclusionRuleProps = ExclusionRuleType & {
  refetchInspector: () => Promise<void>;
};

const wrapperStyle = css`
  display: grid;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;

  grid-template-columns: auto 1rem;
`;

const ExclusionRule: FC<ExclusionRuleProps> = ({ id, exclude_from, exclude_until, refetchInspector }) => {
  const { mutateAsync: deleteExclusionRule } = useMutation(createDeletePreInspectionScheduleRulesExclusionAPI(id));

  const onDeleteClick = async () => {
    await deleteExclusionRule();
    await refetchInspector();
    toast.success("평가 불가능 날짜가 삭제되었습니다.");
  };

  return (
    <div key={id} css={wrapperStyle}>
      <Typography variant="body_1">
        - {dayjs(exclude_from).format("YYYY년 MM월 DD일(ddd) HH시 mm분")} ~{" "}
        {dayjs(exclude_until).format("YYYY년 MM월 DD일(ddd) HH시 mm분")}
      </Typography>
      <IconButton icon={EtcDeleteCircleIcon} size={16} onClick={onDeleteClick} color="warning_primary" />
    </div>
  );
};

export default ExclusionRule;
