import type { AxiosResponse } from "axios";
import { parse } from "http-link-header";
import type Link from "http-link-header";

type InfiniteQueryHeadersMeta = {
  link?: Link;
  count?: number;
  pageSize?: number;
};

export type PaginatedResponse<TData> = {
  meta: InfiniteQueryHeadersMeta;
  data: TData;
};

export const parsePaginatedResponse = <TData>(apiResponse: AxiosResponse<TData>): PaginatedResponse<TData> => {
  const { headers, data } = apiResponse;

  const response: PaginatedResponse<TData> = { meta: {}, data };

  response.meta = {
    link: headers.link ? parse(headers.link) : undefined,
    count: headers["x-pagination-count"] ? parseInt(headers["x-pagination-count"]) : undefined,
    pageSize: headers["x-pagination-page-size"] ? parseInt(headers["x-pagination-page-size"]) : undefined,
  };

  return response;
};
