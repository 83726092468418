import type { FC } from "react";

import { css } from "@emotion/react";
import { Typography, colors } from "@PRNDcompany/heydealer-ui";
import { Link, useLocation } from "react-router-dom";

import type { RouteType } from "src/constants/routes";

type SidebarRouteProps = RouteType;

const linkStyle = css`
  box-sizing: border-box;

  padding: 1rem 1.5rem;

  color: ${colors.base_dark};

  marker: none;
  list-style: none;

  @media (hover: hover) {
    :hover {
      background: ${colors.base_gray2};
    }
  }
`;

const activeLinkStyle = css`
  background: ${colors.base_gray4};
`;

const SidebarRoute: FC<SidebarRouteProps> = ({ path: routePath, name, params }) => {
  const { pathname } = useLocation();

  return (
    <Link to={`${routePath}?${new URLSearchParams(params).toString()}`}>
      <li css={[linkStyle, routePath === pathname && activeLinkStyle]}>
        <Typography variant="body_1">{name}</Typography>
      </li>
    </Link>
  );
};

export default SidebarRoute;
