import { useQuery } from "react-query";

import { queryKeys } from "src/constants/queryKeys";

import queryClient from "./queryClient";

const localStorageTokenKey = "token";
const getAuthToken = () => localStorage.getItem(localStorageTokenKey);

// 서버 API 에 따라 cookie로 관리되도록 변경될 수 있음

export const useAuthToken = () => {
  const { data: token } = useQuery({
    queryKey: queryKeys.authToken(),
    queryFn: getAuthToken,
    initialData: getAuthToken(),
  });

  return token;
};

export const setAuthToken = (token: string) => {
  queryClient.setQueryData(queryKeys.authToken(), token);
  localStorage.setItem(localStorageTokenKey, token);
};
