import taxios from "@PRNDcompany/taxios";

import type { PaginatedResponse } from "src/utils/parsePaginatedResponse";
import { parsePaginatedResponse } from "src/utils/parsePaginatedResponse";

import type { Post } from "./types";

type GetPostsResponse = PaginatedResponse<Post[]>;

export const getPostsAPI = async (params: { page: number }): Promise<GetPostsResponse> =>
  parsePaginatedResponse(await taxios.get<Post[]>("/posts/", { params }));
