import type { FC } from "react";

import { css } from "@emotion/react";
import { colors, effects } from "@PRNDcompany/heydealer-ui";

const paperStyle = css`
  box-sizing: border-box;

  border: 1px solid ${colors.base_gray3};
  border-radius: 0.25rem;

  background: ${colors.base_white};
  box-shadow: ${effects.component_navy_8};
`;

const Paper: FC = ({ children }) => <div css={paperStyle}>{children}</div>;

export default Paper;
