import type { FC } from "react";

import { css } from "@emotion/react";
import { Button, SearchIcon, SinglelineTextfield, Typography } from "@PRNDcompany/heydealer-ui";
import type { SubmitHandler } from "react-hook-form";
import { FormProvider, useController, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

import CompanyDropdown from "../CompanyDropdown";
import StatusDropdown from "../StatusDropdown";

export type Values = {
  name: string | null;
  company_id: string | null;
  status: string | null;
};

const wrapperStyle = css`
  display: grid;
  gap: 1rem;
`;

const rowStyle = css`
  display: grid;
  gap: 0.5rem;
`;

type FilterGroupProps = {
  onSearch: () => void;
};

const FilterGroup: FC<FilterGroupProps> = ({ onSearch }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const methods = useForm<Values>({
    defaultValues: {
      name: searchParams.get("name") || null,
      company_id: searchParams.get("company_id") || null,
      status: searchParams.get("status") || null,
    },
  });

  const { handleSubmit: createHandleSubmit, control } = methods;

  const { value: name, onChange: handleNameChange } = useController({ control, name: "name" }).field;
  const { value: companyId, onChange: handleCompanyIdChange } = useController({ control, name: "company_id" }).field;
  const { value: status, onChange: handleStatusChange } = useController({ control, name: "status" }).field;

  const handleValidSubmit: SubmitHandler<Values> = (data) => {
    const params = new URLSearchParams(searchParams);

    Object.entries(data).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        params.set(key, String(value));
      } else {
        params.delete(key);
      }
    });

    setSearchParams(params);
    onSearch();
  };

  return (
    <FormProvider {...methods}>
      <div css={wrapperStyle}>
        <div css={{ display: "flex", gap: "1rem" }}>
          <div css={rowStyle}>
            <Typography variant="subtitle_3">평가사</Typography>
            <SinglelineTextfield width={200} size={48} name="name" value={name || ""} onChange={handleNameChange} />
          </div>
          <div css={rowStyle}>
            <Typography variant="subtitle_3">소속업체</Typography>
            <CompanyDropdown value={companyId || ""} onChange={handleCompanyIdChange} />
          </div>
          <div css={rowStyle}>
            <Typography variant="subtitle_3">상태</Typography>
            <StatusDropdown value={status || ""} onChange={handleStatusChange} />
          </div>
        </div>
        <div>
          <Button variant="primary" size={48} icon={SearchIcon} onClick={createHandleSubmit(handleValidSubmit)}>
            검색
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};

export default FilterGroup;
