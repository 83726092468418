import type { FC } from "react";

import { css } from "@emotion/react";
import { Typography } from "@PRNDcompany/heydealer-ui";

const rowStyle = css`
  display: grid;

  align-content: start;

  grid-template-columns: 7rem 1fr;
`;

const FilterRow: FC<{ title: string }> = ({ title, children }) => (
  <div css={rowStyle}>
    <div css={{ height: "3rem", display: "grid", alignItems: "center" }}>
      <Typography variant="body_1">{title}</Typography>
    </div>
    {children}
  </div>
);

export default FilterRow;
