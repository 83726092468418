import type { FC, FormEventHandler, PropsWithChildren } from "react";
import { useEffect, useRef } from "react";

import { css } from "@emotion/react";

type FilterFormProps = PropsWithChildren<{
  defaultParams: URLSearchParams;
  applyFilterParams: (filterParams: URLSearchParams) => void;
}>;

const formDataToParams = (formData: FormData) =>
  new URLSearchParams(
    Array.from(formData.entries())
      .map(([key, value]) => [key, value.toString()])
      .filter(([, value]) => !!value)
  );

const wrapperStyle = css`
  display: grid;
  gap: 0.5rem;
`;

const FilterForm: FC<FilterFormProps> = ({ defaultParams, applyFilterParams, children }) => {
  const formRef = useRef<HTMLFormElement>(null);
  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current || undefined);

    applyFilterParams(formDataToParams(formData));
  };

  useEffect(() => {
    if (!formRef.current) {
      return;
    }

    formRef.current.reset();
  }, [defaultParams]);

  return (
    <form css={wrapperStyle} ref={formRef} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export default FilterForm;
