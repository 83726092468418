import { css } from "@emotion/react";
import { colors } from "@PRNDcompany/heydealer-ui";

export const wrapperStyle = css`
  display: grid;
  align-items: center;
  gap: 1rem;

  box-sizing: border-box;

  padding: 0.5rem 1rem;

  grid-template-columns: 7.5rem 1fr;

  min-height: 2.25rem;

  :hover {
    background-color: ${colors.base_gray3};
  }
`;

export const buttonWrapperStyle = css`
  display: grid;
  justify-content: end;

  margin: -0.5rem 0;

  grid-auto-flow: column;
`;
