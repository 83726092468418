import type { VFC } from "react";
import { Suspense, useState } from "react";

import { css } from "@emotion/react";
import { useSearchParams } from "react-router-dom";

import SearchPlaceholder from "src/components/SearchPlaceholder";
import Page from "src/components/templates/Page";

import CarList from "./CarList";
import FilterGroup from "./FilterGroup";

const containerStyle = css`
  display: grid;
  gap: 2rem;

  padding: 2rem;
`;

const CarListPage: VFC = () => {
  const [isSearched, setIsSearched] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Page>
      <div css={containerStyle}>
        <Suspense fallback={null}>
          <FilterGroup
            defaultParams={searchParams}
            applyFilterParams={(filterParams) => {
              setSearchParams(filterParams);
              setIsSearched(true);
            }}
          />
        </Suspense>
        {isSearched ? <CarList /> : <SearchPlaceholder />}
      </div>
    </Page>
  );
};

export default CarListPage;
