import taxios from "@PRNDcompany/taxios";

import type { Coordinate } from "./types";

export type GetAccidentRepairsImageResponse = {
  image_url: string;
  image_width: number;
  coordinates: Coordinate[];
};

export const getAccidentRepairsImageAPI = async (params: URLSearchParams) => {
  return (await taxios.get<GetAccidentRepairsImageResponse>(`/accident_repairs_image/`, { params })).data;
};
