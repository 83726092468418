import { css } from "@emotion/react";

export default css`
  :root {
    font-size: 14px;
  }

  a {
    color: initial;

    text-decoration: inherit;
  }

  input[type="date"] {
    position: relative;

    &::-webkit-calendar-picker-indicator {
      position: absolute;

      width: 100%;
      height: 100%;

      opacity: 0;

      z-index: 1;
    }
  }
`;
