import type { ChangeEventHandler, FC, FormEventHandler } from "react";
import React, { useState } from "react";

import { useMutation, useQueryClient } from "react-query";

import { createPostInspectorCommentsAPI } from "src/apis";
import { queryKeys } from "src/constants/queryKeys";

import CommentForm from "./CommentForm";

type CommentCreateFormProps = {
  inspectorHashId: string;
};

const CommentCreateForm: FC<CommentCreateFormProps> = ({ inspectorHashId }) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: createPostInspectorCommentsAPI(inspectorHashId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.inspectors.byHashId.comments(inspectorHashId),
      });
    },
  });

  const [content, setContent] = useState("");

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (content.trim() === "") {
      return;
    }

    await mutateAsync({ content });
    setContent("");
  };

  const handleContentChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setContent(e.target.value);
  };

  return (
    <CommentForm content={content} onContentChange={handleContentChange} onSubmit={handleSubmit} loading={isLoading} />
  );
};

export default CommentCreateForm;
