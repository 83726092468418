import type { VFC } from "react";
import { useState } from "react";

import { css } from "@emotion/react";
import { ArrowLeftIcon, ArrowRightIcon, colors } from "@PRNDcompany/heydealer-ui";

import getResizeUrl from "src/utils/getResizeURL";

type CarouselProps = {
  imageURLs: string[];
};

const ASPECT_RATIO = 2 / 3;

const IMAGE_WIDTH = 450;
const IMAGE_HEIGHT = IMAGE_WIDTH * ASPECT_RATIO;

const imageStyle = css`
  width: ${IMAGE_WIDTH / 16}rem;
  height: ${IMAGE_HEIGHT / 16}rem;

  background: ${colors.base_dark};

  object-fit: contain;
`;

const thumbnailWrapperStyle = css`
  display: grid;
  gap: 0.25rem;

  width: ${IMAGE_WIDTH / 16}rem;

  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: ${(((IMAGE_WIDTH - 1.25) / 6) * ASPECT_RATIO) / 16}rem;
`;

const imageWrapperStyle = css`
  position: relative;

  width: fit-content;
`;

const navigateButtonStyle = css`
  all: initial;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 50%;

  width: 2.5rem;
  height: 2.5rem;

  border-radius: 1.25rem;

  transform: translateY(-50%);

  background-color: ${colors.base_32};
  cursor: pointer;
`;

const prevButtonStyle = [
  navigateButtonStyle,
  css`
    left: 0;
  `,
];

const nextButtonStyle = [
  navigateButtonStyle,
  css`
    right: 0;
  `,
];

const Carousel: VFC<CarouselProps> = ({ imageURLs }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const onNextButtonClick = () => {
    setCurrentImageIndex((prev) => (prev < imageURLs.length - 1 ? prev + 1 : prev));
  };

  const onPrevButtonClick = () => {
    setCurrentImageIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const onThumbnailMouseOver = (index: number) => {
    setCurrentImageIndex(index);
  };

  const getThumbnailStyle = (isActive: boolean) => [
    css`
      box-sizing: border-box;
      width: 100%;
      height: 100%;

      background-color: ${colors.base_dark};

      object-fit: contain;
    `,
    isActive &&
      css`
        border: 2px solid ${colors.brand_light};
      `,
  ];

  return (
    <div>
      <div css={imageWrapperStyle}>
        <img src={getResizeUrl(IMAGE_WIDTH, 0, imageURLs[currentImageIndex])} css={imageStyle} />
        <button onClick={onPrevButtonClick} css={prevButtonStyle}>
          <ArrowLeftIcon size={24} color="base_white" />
        </button>
        <button onClick={onNextButtonClick} css={nextButtonStyle}>
          <ArrowRightIcon size={24} color="base_white" />
        </button>
      </div>
      <div css={thumbnailWrapperStyle}>
        {imageURLs.map((imageURL, index) => (
          <img
            src={getResizeUrl(IMAGE_WIDTH / 6, 0, imageURL)}
            key={index}
            css={getThumbnailStyle(index === currentImageIndex)}
            onMouseOver={() => onThumbnailMouseOver(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
