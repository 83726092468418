import { Typography } from "@PRNDcompany/heydealer-ui";

const SearchPlaceholder = () => {
  return (
    <Typography variant="body_1" customCSS={{ textAlign: "center" }}>
      검색 버튼을 눌러 조회할 수 있습니다.
    </Typography>
  );
};

export default SearchPlaceholder;
