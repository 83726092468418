import type { RefCallback } from "react";
import { useCallback, useRef } from "react";

/**
 * @todo Remove this hook when React 18.3.0, which includes ref cleanup, is released
 * @see https://github.com/facebook/react/pull/25686
 **/
export const useCallbackRef = <T>(callback: (element: T) => () => void, dependencies?: unknown[]) => {
  const cleanupRef = useRef<() => void>();
  const ref: RefCallback<T> = useCallback((element) => {
    cleanupRef.current?.();
    cleanupRef.current = undefined;

    if (!element) {
      return;
    }

    cleanupRef.current = callback(element);
  }, dependencies ?? []);

  return ref;
};
