import type { FC } from "react";
import React, { Fragment, Suspense } from "react";

import { Divider } from "@PRNDcompany/heydealer-ui";

import { useAccordionContext } from "src/components/Accordion";

import PostDetail from "./PostDetail";

type PostAccordionContentProps = {
  hashId: string;
};

const PostAccordionContent: FC<PostAccordionContentProps> = ({ hashId }) => {
  const { isOpen } = useAccordionContext();

  if (!isOpen) {
    return null;
  }

  return (
    <Suspense fallback={<Fragment></Fragment>}>
      <Divider color="base_gray5" />
      <PostDetail hashId={hashId} />
    </Suspense>
  );
};

export default PostAccordionContent;
