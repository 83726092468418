import type { VFC } from "react";

import { css } from "@emotion/react";
import { Divider } from "@PRNDcompany/heydealer-ui";

import type { ConditionData } from "src/apis/cars/types";

import CarConditionDataListItem from "./CarConditionDataListItem";

type CarConditionProps = {
  conditionData: ConditionData;
};

const containerStyle = css`
  display: grid;
  gap: 1rem;
`;

const CarCondition: VFC<CarConditionProps> = ({ conditionData: { basic, extra } }) => (
  <div css={containerStyle}>
    <div>
      {basic.map((field) => (
        <CarConditionDataListItem field={field} key={field.label} />
      ))}
    </div>
    <Divider />
    <div>
      {extra.map((field) => (
        <CarConditionDataListItem field={field} key={field.label} />
      ))}
    </div>
  </div>
);

export default CarCondition;
