import type { FC } from "react";
import React from "react";

import { Dropdown } from "@PRNDcompany/heydealer-ui";
import { useQuery } from "react-query";

import { getCompaniesAPI } from "src/apis";
import { queryKeys } from "src/constants/queryKeys";

type CompanyDropdownProps = {
  value: string;
  onChange: (value: string) => void;
};

const CompanyDropdown: FC<CompanyDropdownProps> = ({ value, onChange }) => {
  const { data } = useQuery({
    queryKey: queryKeys.companies(),
    queryFn: getCompaniesAPI,
  });

  const options = [
    { value: "", children: "전체" },
    ...(data || []).map((company) => ({
      children: company.name,
      value: company.id.toString(),
    })),
  ];

  return <Dropdown width={200} value={value} onChange={onChange} size={48} options={options} />;
};

export default CompanyDropdown;
