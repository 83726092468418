import type { VFC } from "react";

import { css } from "@emotion/react";
import { Typography, colors } from "@PRNDcompany/heydealer-ui";

import type { Car, Inspection } from "src/apis/cars/types";
import Table from "src/components/atoms/Table";

type AccidentRepairDiffProps = {
  car: Car;
};

const inspectionHeaderKeys: [
  string,
  keyof Pick<Inspection, "part_display" | "examination_repair_display" | "repair_display">
][] = [
  ["부위", "part_display"],
  ["성능점검", "examination_repair_display"],
  ["평가사", "repair_display"],
];

const containerStyle = css`
  display: grid;
  gap: 1rem;

  align-content: start;

  word-break: keep-all;
  white-space: nowrap;
`;

const inspectionTableWrapperStyle = css`
  display: grid;
  gap: 0.5rem;

  padding: 1rem;
`;

const overInspectionTableWrapperStyle = css`
  background: #22e2ac;
`;

const underInspectionTableWrapperStyle = css`
  background: #ffb020;
`;

const inspectionRecordsImageLinkStyle = css`
  display: inline-block;

  color: ${colors.brand_light};

  font-size: 12px;
  text-decoration: underline;
`;

const AccidentRepairDiff: VFC<AccidentRepairDiffProps> = ({ car }) => {
  const { over_inspection, under_inspection } = car.accident_repairs_diff;
  const { is_compensated, inspection_records_image_url } = car;

  return (
    <div css={containerStyle}>
      {inspection_records_image_url && (
        <a href={inspection_records_image_url} target="_blank" css={inspectionRecordsImageLinkStyle}>
          성능점검
        </a>
      )}

      {over_inspection.length > 0 && (
        <div css={[inspectionTableWrapperStyle, overInspectionTableWrapperStyle]}>
          <Typography variant="subtitle_3">사고부위 과진단 차량입니다.</Typography>
          <Table headerKeys={inspectionHeaderKeys} rows={over_inspection} />
        </div>
      )}

      {under_inspection.length > 0 && (
        <div css={[inspectionTableWrapperStyle, underInspectionTableWrapperStyle]}>
          <Typography variant="subtitle_3">사고부위 오진단 차량입니다.</Typography>
          <Table headerKeys={inspectionHeaderKeys} rows={under_inspection} />
        </div>
      )}

      {is_compensated && (
        <div css={{ background: colors.critical_8, padding: "0.5rem" }}>
          <Typography variant="body_2">클레임 보상 차량 입니다.</Typography>
        </div>
      )}
    </div>
  );
};

export default AccidentRepairDiff;
