import type { FC } from "react";
import React from "react";

import { Button } from "@PRNDcompany/heydealer-ui";
import { useMutation, useQueryClient } from "react-query";

import { createDeleteInspectorCommentAPI } from "src/apis";
import { queryKeys } from "src/constants/queryKeys";

type CommentDeleteButtonProps = {
  commentId: number;
  inspectorHashId: string;
};

const CommentDeleteButton: FC<CommentDeleteButtonProps> = ({ inspectorHashId, commentId }) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: createDeleteInspectorCommentAPI(commentId),
  });

  const handleClick = async () => {
    await mutateAsync();

    queryClient.invalidateQueries({
      queryKey: queryKeys.inspectors.byHashId.comments(inspectorHashId),
    });
  };

  return (
    <Button variant="tiny" size={32} color="red" onClick={handleClick} loading={isLoading}>
      삭제
    </Button>
  );
};

export default CommentDeleteButton;
