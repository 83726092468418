import type { VFC } from "react";

import { css } from "@emotion/react";
import { colors, Typography, UnderneathIcon } from "@PRNDcompany/heydealer-ui";

import type { ConditionField as IConditionField } from "src/apis/cars/types";

type CarConditionDataListItemProps = {
  field: IConditionField;
};

const containerStyle = css`
  display: flex;
  justify-content: space-between;

  margin: 0;
`;

const descriptionStyle = css`
  display: flex;
  align-items: center;
  gap: 0.25rem;

  color: ${colors.base_gray6};
`;

const CarConditionDataListItem: VFC<CarConditionDataListItemProps> = ({ field }) => {
  const { label, text, notice, description } = field;

  return (
    <div>
      <div css={containerStyle}>
        <Typography variant="body_2">{label}</Typography>
        <Typography variant="body_2" color={notice ? "warning_light" : "base_primary"}>
          {text}
        </Typography>
      </div>
      {description && (
        <div css={descriptionStyle}>
          <UnderneathIcon size={16} />
          <Typography variant="body_2">{description}</Typography>
        </div>
      )}
    </div>
  );
};

export default CarConditionDataListItem;
