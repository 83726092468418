import type { FC } from "react";

import { css } from "@emotion/react";
import { Typography } from "@PRNDcompany/heydealer-ui";

import type { RouteGroupType } from "src/constants/routes";

import SidebarRoute from "./SidebarRoute";

const groupStyle = css`
  padding: 0.5rem 0;
`;

const groupLabelStyle = css`
  padding: 0.5rem 1.5rem;

  list-style: none;
`;

const SidebarGroup: FC<RouteGroupType> = ({ label, routes }) => (
  <div css={groupStyle}>
    {label && (
      <li css={groupLabelStyle}>
        <Typography variant="subtitle_3" color="base_gray6">
          {label}
        </Typography>
      </li>
    )}
    {routes.map((route) => (
      <SidebarRoute key={route.path} {...route} />
    ))}
  </div>
);

export default SidebarGroup;
