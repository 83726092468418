import type { VFC } from "react";
import { useState } from "react";

import { css } from "@emotion/react";
import {
  Typography,
  SinglelineTextfield,
  SelectForm,
  colors,
  Tooltip,
  InfoInformationIcon,
} from "@PRNDcompany/heydealer-ui";
import dayjs from "dayjs";
import { useController, useFormContext } from "react-hook-form";

import { format } from "src/modules/dayjs";

import type { FilterGroupFormValues } from "./FilterGroup";

export type DateRangePickerProps = {
  startDate: string | null;
  endDate: string | null;
  onChange: (startDate: string | null, endDate: string | null) => void;
};

const DATE_RANGE_OPTIONS: [label: string, start: string, end: string][] = [
  ["지난 1주일", format(dayjs().add(-1, "week")), format(dayjs())],
  ["이번 달", format(dayjs().startOf("month")), format(dayjs().endOf("month"))],
  ["지난 달", format(dayjs().add(-1, "month").startOf("month")), format(dayjs().add(-1, "month").endOf("month"))],
  ["지난 1개월", format(dayjs().add(-1, "month")), format(dayjs())],
  ["지난 3개월", format(dayjs().add(-3, "month")), format(dayjs())],
  ["지난 6개월", format(dayjs().add(-6, "month")), format(dayjs())],
  ["지난 1년", format(dayjs().add(-1, "year")), format(dayjs())],
  ["지난 3년", format(dayjs().add(-3, "year")), format(dayjs())],
];

const rowStyle = css`
  display: grid;
  justify-content: start;
  align-items: center;

  grid-column-gap: 0.75rem;

  grid-auto-flow: column;

  white-space: pre;
`;

const RangePicker: VFC = () => {
  const { control, setValue } = useFormContext<FilterGroupFormValues>();
  const { value: startDate, onChange: handleStartDateChange } = useController({ control, name: "start_date" }).field;
  const { value: endDate, onChange: handleEndDateChange } = useController({ control, name: "end_date" }).field;
  const {
    field: { value: recentInspectedCarsCount, onChange: handleRecentInspectedCarsCountChange },
  } = useController({ control, name: "recent_inspected_cars_count" });

  const [isRecentInspectedCarsCountMode, setIsRecentInspectedCarsCountMode] = useState(
    typeof recentInspectedCarsCount === "number"
  );

  const createHandleRangeButtonClick = (start: string, end: string) => () => {
    setIsRecentInspectedCarsCountMode(false);
    setValue("recent_inspected_cars_count", null);
    handleStartDateChange(start);
    handleEndDateChange(end);
  };

  return (
    <div css={{ display: "grid", gap: "0.75rem" }}>
      <div css={rowStyle}>
        <SinglelineTextfield
          size={48}
          type={!isRecentInspectedCarsCountMode ? "date" : "text"}
          value={startDate ?? ""}
          onChange={(e) => handleStartDateChange(e.currentTarget.value)}
          width={180}
          disabled={isRecentInspectedCarsCountMode}
        />
        <Typography variant="body_1">~</Typography>
        <SinglelineTextfield
          size={48}
          type="date"
          value={endDate || ""}
          onChange={(e) => handleEndDateChange(e.currentTarget.value)}
          width={180}
        />

        <Typography variant="body_1">/</Typography>

        <Typography variant="body_1">
          <div
            css={[
              { display: "flex", alignItems: "center", gap: "0.5rem" },
              !isRecentInspectedCarsCountMode && { color: colors.base_gray4 },
            ]}
          >
            <div>최근</div>
            <SinglelineTextfield
              key={`${isRecentInspectedCarsCountMode}`}
              size={48}
              formatType="numeric"
              value={isRecentInspectedCarsCountMode ? recentInspectedCarsCount ?? "" : ""}
              onValueChange={({ floatValue }) => handleRecentInspectedCarsCountChange(floatValue ?? null)}
              disabled={!isRecentInspectedCarsCountMode}
              width={120}
            />
            <div>대</div>
            <Tooltip
              placement="right"
              width={500}
              content="진단완료, 거래완료, 성능지 검토완료가 모두 설정한 대수 기준으로 출력됩니다."
            >
              <InfoInformationIcon size={16} color="base_gray5" />
            </Tooltip>
          </div>
        </Typography>
      </div>
      <div css={rowStyle}>
        {DATE_RANGE_OPTIONS.map(([label, start, end]) => (
          <SelectForm
            key={label}
            size={48}
            label={label}
            onClick={createHandleRangeButtonClick(start, end)}
            checked={start === startDate && end === endDate}
          />
        ))}
        <div />
        <SelectForm
          size={48}
          label="최근 진단 대수"
          checked={isRecentInspectedCarsCountMode}
          onClick={() => {
            setIsRecentInspectedCarsCountMode(true);
            setValue("start_date", null);
          }}
        />
      </div>
    </div>
  );
};

export default RangePicker;
