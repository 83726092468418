import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

import type { GetInspectorsStatsParams } from "src/apis";
import { format } from "src/modules/dayjs";

export const useInspectorsStatsParams = (): GetInspectorsStatsParams => {
  const [searchParams] = useSearchParams();

  const params: GetInspectorsStatsParams = {
    ...(searchParams.get("recent_inspected_cars_count")
      ? { recent_inspected_cars_count: Number(searchParams.get("recent_inspected_cars_count")), start_date: undefined }
      : {
          recent_inspected_cars_count: undefined,
          start_date: searchParams.get("start_date") ?? format(dayjs().subtract(1, "month")),
        }),

    end_date: searchParams.get("end_date") || format(dayjs()),

    company_id: searchParams.get("company_id") ?? undefined,
    status: searchParams.get("status") ?? "active",
  };

  return params;
};
