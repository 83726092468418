import type { FC } from "react";

import { css } from "@emotion/react";
import { Typography, colors } from "@PRNDcompany/heydealer-ui";
import dayjs from "dayjs";

import type { InspectorSchedule } from "src/apis/inspectors/types";

type InspectorScheduleTableProps = {
  schedules: InspectorSchedule[];
};

const statusMap: Record<InspectorSchedule["status"], { text: string; color: string }> = {
  available: {
    text: "예약가능",
    color: colors.success_16,
  },
  reserved: {
    text: "예약됨",
    color: colors.brand_primary,
  },
  unavailable: {
    text: "예약 불가",
    color: colors.base_gray2,
  },
};

const tableStyle = css`
  border-collapse: collapse;

  th,
  td {
    padding: 0.5rem;

    border: 1px solid ${colors.base_gray4};

    color: ${colors.base_primary};

    text-align: center;
    min-width: 70px;
  }

  th {
    background: ${colors.base_gray3};
  }
`;

const timeStyle = css`
  border: 1px solid ${colors.base_gray4};

  background: ${colors.base_gray3};
`;

const footerStyle = css`
  display: grid;
  justify-content: end;
  align-items: center;
  gap: 1.5rem;

  grid-auto-flow: column;
`;

const legendStyle = css`
  display: grid;
  align-items: center;
  gap: 0.5rem;

  grid-auto-flow: column;
`;

const legendColorStyle = css`
  display: inline-block;

  width: 2rem;
  height: 1rem;

  border: 1px solid ${colors.base_gray4};
`;

const InspectorScheduleTable: FC<InspectorScheduleTableProps> = ({ schedules }) => {
  const reducedDatetime = schedules
    .map(({ datetime }) => datetime.split(" "))
    .reduce((prev, [date, time]) => ({ date: prev.date.add(date), time: prev.time.add(time) }), {
      date: new Set<string>(),
      time: new Set<string>(),
    });

  const dates = Array.from(reducedDatetime.date.values())
    .map((date) => dayjs(date))
    .sort((a, b) => a.diff(b))
    .map((date) => [date.format("M월 D일 (ddd)"), date.format("YYYY-MM-DD")]);

  const times = Array.from(reducedDatetime.time.values())
    .sort()
    .map<[string[], string]>((time) => [time.split(":"), time])
    .map(([[hour, minute], time]) => [`${hour}시`.concat(minute !== "00" ? `${minute}분` : ""), time]);

  if (!schedules) {
    return null;
  }

  const groupByDateTime: Record<string, Record<string, InspectorSchedule>> = schedules.reduce((prev, schedule) => {
    const [date, time] = schedule.datetime.split(" ");
    if (!time) {
      throw new Error("wrong datetime format");
    }

    prev[time] = { ...(prev[time] || {}), [date]: schedule };

    return prev;
  }, {} as Record<string, Record<string, InspectorSchedule>>);

  return (
    <div css={{ width: "fit-content", display: "grid", gap: "1.5rem" }}>
      <table css={tableStyle}>
        <thead>
          <th />
          {dates.map(([formattedDate]) => (
            <th key={formattedDate}>
              <Typography variant="subtitle_4" preWrap>
                {formattedDate}
              </Typography>
            </th>
          ))}
        </thead>
        <tbody>
          {times.map(([formattedTime, time]) => (
            <tr key={formattedTime}>
              <th css={timeStyle}>
                <Typography variant="subtitle_4" preWrap>
                  {formattedTime}
                </Typography>
              </th>
              {dates.map(([, date]) => (
                <td
                  key={groupByDateTime[time]?.[date]?.datetime}
                  css={{ background: statusMap[groupByDateTime[time]?.[date]?.status]?.color }}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div css={footerStyle}>
        {Object.values(statusMap).map((legend) => (
          <div css={legendStyle} key={legend.text}>
            <span css={[legendColorStyle, { backgroundColor: legend.color }]} />
            <span>{legend.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InspectorScheduleTable;
