import type { VFC } from "react";

import { Typography } from "@PRNDcompany/heydealer-ui";

import type { Car, Fuel, Transmission } from "src/apis/cars/types";
import isNil from "src/utils/isNil";

type CarSpecProps = {
  car: Car;
};

const fuelMap: Record<Fuel, string> = {
  gasoline: "휘발유",
  diesel: "디젤",
  lpg: "LPG",
  hybrid: "하이브리드",
  bifuel: "바이퓨얼",
  electric: "전기",
  hydrogen: "수소",
};

const transmissionMap: Record<Transmission, string> = {
  auto: "자동",
  manual: "수동",
};

const CarSpec: VFC<CarSpecProps> = ({ car }) => {
  const { car_number, model_part_name, grade_part_name, year, fuel, transmission, mileage, color, interior } =
    car.detail;

  return (
    <div>
      <Typography variant="subtitle_1">{car_number}</Typography>
      <Typography variant="body_2">
        {model_part_name} {grade_part_name} {year}년식
      </Typography>
      <Typography variant="body_2">
        {[
          fuel && fuelMap[fuel],
          transmission && transmissionMap[transmission],
          mileage && `${mileage.toLocaleString()}km`,
        ]
          .filter((x) => !isNil(x))
          .join(" / ")}
      </Typography>
      <Typography variant="body_2">{[color, interior].filter((x) => !isNil(x)).join(" / ")}</Typography>
    </div>
  );
};

export default CarSpec;
