import taxios from "@PRNDcompany/taxios";

import type { PaginatedResponse } from "src/utils/parsePaginatedResponse";
import { parsePaginatedResponse } from "src/utils/parsePaginatedResponse";

import type { Car } from "./types";

export type GetCarsParams = {
  inspector?: string;
  car_number?: string;
  status?: string;
  examination_status?: string;
  is_over_inspected?: boolean;
  is_under_inspected?: boolean;
  is_compensated?: boolean;
  min_inspected_date?: string;
  max_inspected_date?: string;
  min_traded_date?: string;
  max_traded_date?: string;
};
type GetCarsResponse = PaginatedResponse<Car[]>;

export const getCarsAPI = async (
  params: GetCarsParams & { page?: number; meta_only?: boolean }
): Promise<GetCarsResponse> => {
  return parsePaginatedResponse(await taxios.get<Car[]>("/cars/", { params }));
};
