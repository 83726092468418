import type { FC } from "react";
import React from "react";

import Accordion from "src/components/Accordion";

import CommentListAccordionContent from "./CommentListAccordionContent";
import type { CommentListAccordionContentProps } from "./CommentListAccordionContent";
import CommentListAccordionHeader from "./CommentListAccordionHeader";

type CommentListAccordionProps = CommentListAccordionContentProps;

const CommentListAccordion: FC<CommentListAccordionProps> = (props) => {
  return (
    <Accordion header={<CommentListAccordionHeader />}>
      <CommentListAccordionContent {...props} />
    </Accordion>
  );
};

export default CommentListAccordion;
