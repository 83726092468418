import type { PropsWithChildren } from "react";
import { useState } from "react";

import { Button, DownloadIcon } from "@PRNDcompany/heydealer-ui";
import dayjs from "dayjs";
import { utils as xlsxUtils, writeFile as writeXLSX } from "xlsx";

import type { HeaderKey } from "./SortableTable";

export type ExcelExportButtonProps<TData extends Record<string, unknown>> = PropsWithChildren<{
  headerKeys: HeaderKey<TData>[];
  data?: TData[];
  filename: string;
}>;

const ExcelExportButton = <TData extends Record<string, unknown>>({
  headerKeys,
  data,
  filename,
  children,
}: ExcelExportButtonProps<TData>) => {
  const [isLoading, setLoading] = useState(false);

  const onClick = () => {
    if (!data) {
      return;
    }

    setLoading(true);
    const xlsxList = data.map((row) =>
      headerKeys.reduce(
        (acc, { label, key, formatter }) => ({ ...acc, [label]: formatter?.(row[key]) ?? row[key] ?? "" }),
        {}
      )
    );

    const workSheet = xlsxUtils.json_to_sheet(xlsxList, { header: headerKeys.map(({ label }) => label) });
    const workBook = xlsxUtils.book_new();
    xlsxUtils.book_append_sheet(workBook, workSheet);
    writeXLSX(workBook, `${filename}-${dayjs().format("YYYYMMDDHHmmss")}.xlsx`);
    setLoading(false);
  };

  return (
    <Button variant="secondary" size={48} onClick={onClick} loading={isLoading} disabled={!data} icon={DownloadIcon}>
      {children}
    </Button>
  );
};

export default ExcelExportButton;
