import type { FC } from "react";

import { css } from "@emotion/react";
import { Divider, Typography } from "@PRNDcompany/heydealer-ui";
import { useQuery } from "react-query";

import { createGetInspectorAPI } from "src/apis";
import type { Inspector } from "src/apis/inspectors/types";
import Paper from "src/components/atoms/Paper";
import InspectorScheduleTable from "src/components/molecules/InspectorScheduleTable";
import { queryKeys } from "src/constants/queryKeys";

import CommentListAccordion from "./CommentListAccordion";
import ExclusionRules from "./ExclusionRules";
import PerformanceTable from "./PerformanceTable";
import ScheduleRule from "./ScheduleRule";

type InspectorListItemProps = {
  inspector: Inspector;
  updateInspector: (hashId: Inspector["hash_id"], inspector: Inspector | null) => void;
};

const profileWrapperStyle = css`
  display: grid;
  gap: 2rem;

  padding: 1rem;

  grid-template-columns: 5rem 1fr;
`;

const profileImageStyle = css`
  width: 5rem;
  height: 5rem;

  border-radius: 50%;

  overflow: hidden;
`;

const InspectorListItem: FC<InspectorListItemProps> = ({ inspector, updateInspector }) => {
  const { refetch } = useQuery({
    queryKey: queryKeys.inspectors.byHashId.all(inspector.hash_id),
    queryFn: createGetInspectorAPI(inspector.hash_id),
    enabled: false,
  });

  const refetchInspector = async () => {
    const { data: fetched = null } = await refetch();
    updateInspector(inspector.hash_id, fetched);
  };

  return (
    <Paper>
      <div css={{ display: "grid", gap: "2rem", padding: "1.125rem" }}>
        <div css={profileWrapperStyle}>
          <img css={profileImageStyle} src={inspector.profile_image_url} />
          <div>
            <div css={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
              <Typography variant="subtitle_1">{inspector.name} 평가사</Typography>
              <Typography variant="body_2">({inspector.company_name})</Typography>
            </div>
            <Typography variant="body_2">
              {inspector.phone_number?.replace(/^([0-9]{3})([0-9]{4})([0-9]{4})$/g, "$1-$2-$3")}
            </Typography>
          </div>
        </div>
        <div css={{ display: "grid", gap: "1rem" }}>
          <Typography variant="subtitle_1">실적</Typography>
          <PerformanceTable performance={inspector.performance} />
        </div>
        <div css={{ display: "grid", gap: "1rem" }}>
          <Typography variant="subtitle_1">평가 일정</Typography>
          <InspectorScheduleTable schedules={inspector.inspection_schedule} />
        </div>
        <div css={{ display: "grid", gap: "1rem" }}>
          <Typography variant="subtitle_1">평가가능 요일</Typography>
          <div css={{ display: "grid", gap: "1rem", gridTemplateColumns: "repeat(3, auto)", justifyItems: "center" }}>
            <Typography variant="subtitle_2">요일</Typography>
            <Typography variant="subtitle_2">시간</Typography>
            <Typography variant="subtitle_2">지역</Typography>
            {inspector.schedule_rules.map(({ weekdays, time_ranges, locations }) => (
              <ScheduleRule
                key={weekdays.toString()}
                weekdays={weekdays}
                time_ranges={time_ranges}
                locations={locations}
              />
            ))}
          </div>
        </div>
        <div css={{ display: "grid", gap: "1rem" }}>
          <Typography variant="subtitle_1">평가 불가능 날짜</Typography>
          <ExclusionRules
            hashId={inspector.hash_id}
            exclusionRules={inspector.exclusion_rules}
            refetchInspector={refetchInspector}
          />
        </div>
      </div>
      <Divider />
      <CommentListAccordion initialData={inspector.comments} inspectorHashId={inspector.hash_id} />
    </Paper>
  );
};

export default InspectorListItem;
