import type { FC } from "react";
import { useState, Fragment } from "react";

import type { ColorToken } from "@PRNDcompany/heydealer-ui";
import { Button, Typography } from "@PRNDcompany/heydealer-ui";
import dayjs from "dayjs";

import type { Inspector } from "src/apis/inspectors/types";

const weekdayColorTokenArray: ColorToken[] = [
  "base_dark",
  "base_dark",
  "base_dark",
  "base_dark",
  "base_dark",
  "brand_primary",
  "warning_primary",
];

const convertRangeTime = (time: number): string => `${time / 60}시 ${(time % 60).toString().padStart(2, "0")}분`;

const ScheduleRule: FC<Inspector["schedule_rules"][number]> = ({ weekdays, time_ranges, locations }) => {
  const formattedLocations = locations.reduce<string[]>(
    (prev, { name, area_names }) => [...prev, ...area_names.map((area) => `${name} ${area}`)],
    []
  );

  const [sliceLocation, setSliceLocation] = useState(true);

  return (
    <Fragment key={weekdays.toString()}>
      <div css={{ display: "flex", gap: "0.5rem" }}>
        {weekdays.map((w) => (
          <Typography key={w} variant="body_1" color={weekdayColorTokenArray[w]}>
            {dayjs()
              .day(w + 1)
              .format("ddd")}
          </Typography>
        ))}
      </div>
      <div css={{ justifySelf: "start" }}>
        {time_ranges.map(({ start_time, end_time }) => (
          <Typography key={start_time} variant="body_1">
            - {convertRangeTime(start_time)} ~ {convertRangeTime(end_time)}
          </Typography>
        ))}
      </div>
      <div css={{ display: "grid", width: "100%", justifyItems: "end", gap: "0.5rem" }}>
        <div css={{ display: "grid", gap: "0.5rem 1rem", gridTemplateColumns: "repeat(4, minmax(10rem, 1fr))" }}>
          {formattedLocations.slice(0, sliceLocation ? 8 : formattedLocations.length).map((location) => (
            <Typography key={location} variant="body_1">
              - {location}
            </Typography>
          ))}
        </div>
        <Button
          variant="text"
          arrow={sliceLocation ? "down" : "up"}
          onClick={() => setSliceLocation((s) => !s)}
          color="gray"
        >
          가능지역 {sliceLocation ? `전체보기(${formattedLocations.length})` : "숨기기"}
        </Button>
      </div>
    </Fragment>
  );
};

export default ScheduleRule;
