import type { ReactNode } from "react";
import { forwardRef, Fragment } from "react";

import { css } from "@emotion/react";
import { colors } from "@PRNDcompany/heydealer-ui";

import Header from "src/components/organisms/Header";
import Sidebar from "src/components/organisms/Sidebar";

/**
 *  forwardRef<HTMLElement>로 해도 될 것 같은데
 *  사용하는 곳에서 children 타입이 존재하지 않는다는 에러가 발생 원인은 모르겠음.
 *  언젠간 파볼것...
 * **/
type PageProps = {
  children: ReactNode;
};

const mainStyle = css`
  display: flex;

  height: calc(100% - 4rem);
`;

const articleStyle = css`
  width: 100%;
  height: 100%;

  background: ${colors.base_gray1};

  overflow: auto;
`;

const Page = forwardRef<HTMLElement, PageProps>(({ children }, ref) => (
  <Fragment>
    <Header />
    <main css={mainStyle}>
      <Sidebar />
      <article css={articleStyle} ref={ref}>
        {children}
      </article>
    </main>
  </Fragment>
));

export default Page;
