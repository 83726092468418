import type { FC } from "react";

import { css } from "@emotion/react";
import { Skeleton, Typography } from "@PRNDcompany/heydealer-ui";
import { useInfiniteQuery } from "react-query";

import { getPostsAPI } from "src/apis";
import Page from "src/components/templates/Page";
import { queryKeys } from "src/constants/queryKeys";
import { useInfiniteScrollRef } from "src/hooks/useInfiniteScrollRef";

import PostAccordion from "./PostAccordion";

const wrapperStyle = css`
  display: grid;
  gap: 1.5rem;

  padding: 2rem;
`;

const PostsPage: FC = () => {
  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery({
    queryKey: queryKeys.posts.list(),
    queryFn: async ({ pageParam }) => {
      return await getPostsAPI({ page: pageParam?.page || 1 });
    },
    getNextPageParam: (data) => {
      const rel = data.meta?.link?.rel("next")[0];
      if (!rel) {
        return undefined;
      }

      return { page: new URL(rel.uri).searchParams.get("page") };
    },
    keepPreviousData: false,
    cacheTime: 0,
  });

  const scrollEndMarkerRef = useInfiniteScrollRef(fetchNextPage, isFetching);

  const posts = data?.pages.flatMap(({ data }) => data) || [];

  return (
    <Page>
      <div css={wrapperStyle}>
        <Typography variant="headline_2">공지사항</Typography>
        <div>
          {posts.map((post) => (
            <PostAccordion key={post.hash_id} post={post} />
          ))}
          {hasNextPage && (
            <div css={{ display: "grid", gap: "0.5rem" }} ref={scrollEndMarkerRef}>
              <Skeleton customCSS={{ width: "20rem", height: "1.5rem" }} loading />
              <Skeleton customCSS={{ width: "30rem", height: "1.2rem" }} loading />
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

export default PostsPage;
