import type { FC } from "react";
import { Fragment } from "react";

import { FixPIcon, InfoInformationIcon, Tooltip, Typography } from "@PRNDcompany/heydealer-ui";

const PaintedExplanation: FC = () => {
  return (
    <div css={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "0.25rem" }}>
      <FixPIcon size={24} />
      <Typography variant="subtitle_3" color="base_gray6">
        외판 도색판금
      </Typography>
      <Tooltip
        width={420}
        content={
          <Fragment>
            <b>외판 도색판금을 P로 표현하는 이유</b>
            <ul>
              <li>대부분 외판 도색판금은 성능지에 판금(W)이 찍히지 않습니다.</li>
              <li>다만 심한 도색판금은 낮은 확률로 W 체크될 수 있습니다.</li>
            </ul>
          </Fragment>
        }
      >
        <InfoInformationIcon size={16} color="base_gray4" />
      </Tooltip>
    </div>
  );
};

export default PaintedExplanation;
