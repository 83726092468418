import { css } from "@emotion/react";
import { colors } from "@PRNDcompany/heydealer-ui";

type TableProps<TData> = {
  headerKeys: [string, keyof TData][];
  rows: TData[];
};

const tableStyle = css`
  border-collapse: collapse;
`;

const thStyle = css`
  padding: 0.5rem;

  border: 1px solid ${colors.base_gray3};

  background: ${colors.base_white};

  font-size: 0.875rem;
  line-height: 1rem;
  text-align: center;
`;

const tdStyle = css`
  padding: 0.5rem;

  border: 1px solid ${colors.base_gray3};

  font-size: 0.875rem;
  line-height: 1rem;
  text-align: center;
`;

const Table = <TData extends Record<string, string | number | null>>({ headerKeys, rows }: TableProps<TData>) => (
  <table css={tableStyle}>
    <thead>
      <tr>
        {headerKeys.map(([headerName], index) => (
          <th css={thStyle} key={index}>
            {headerName}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {rows.map((row, index) => (
        <tr key={index}>
          {headerKeys.map(([_, headerKey], index) => (
            <td css={tdStyle} key={`${headerKey.toString()}${index}`}>
              {row[headerKey] ?? "-"}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

export default Table;
