import type { FC } from "react";
import React from "react";

import type { InspectorComment } from "src/apis/types";
import { boxStyle } from "src/styles/boxStyle";

import Comment from "./components/Comment";
import CommentCreateForm from "./components/CommentCreateForm";
import useInspectorCommentsQuery from "./hooks/useInspectorCommentsQuery";
import { wrapperStyle } from "./styles";

export type CommentListProps = {
  initialData: InspectorComment[];
  inspectorHashId: string;
};

const CommentList: FC<CommentListProps> = ({ initialData, inspectorHashId }) => {
  const { data } = useInspectorCommentsQuery(inspectorHashId, initialData);

  return (
    <div css={[boxStyle, wrapperStyle]}>
      {data.map((comment, idx) => (
        <Comment key={idx} inspectorHashId={inspectorHashId} initialData={comment} />
      ))}
      <div css={{ padding: "0.5rem 1rem" }}>
        <CommentCreateForm inspectorHashId={inspectorHashId} />
      </div>
    </div>
  );
};

export default CommentList;
