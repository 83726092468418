import type { FC } from "react";
import React from "react";

import { css } from "@emotion/react";
import { ArrowDownIcon, ArrowUpIcon, Typography } from "@PRNDcompany/heydealer-ui";

import type { Post } from "src/apis/posts/types";

import { useAccordionContext } from "../../../components/Accordion";

const buttonStyle = css`
  all: initial;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: 2rem 0 0;

  cursor: pointer;
`;

const contentStyle = css`
  display: grid;
  gap: 0.25rem;
`;

type PostAccordionHeaderProps = {
  post: Post;
};

const PostAccordionHeader: FC<PostAccordionHeaderProps> = ({ post }) => {
  const { isOpen } = useAccordionContext();

  return (
    <button css={buttonStyle}>
      <div css={contentStyle}>
        <Typography variant="subtitle_1">{post.title}</Typography>
        <Typography variant="body_1" color="base_gray6">
          {post.published_at || "-"}
        </Typography>
      </div>
      {isOpen ? <ArrowUpIcon size={24} /> : <ArrowDownIcon size={24} />}
    </button>
  );
};

export default PostAccordionHeader;
