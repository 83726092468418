import taxios from "@PRNDcompany/taxios";

import type { InspectorStat } from "./types";

export type GetInspectorsStatsParams = {
  recent_inspected_cars_count?: number;
  start_date?: string;
  end_date: string;

  company_id?: string;
  status?: string;
};

export type GetInspectorsStatsResponse = InspectorStat[];

export const getInspectorsStatsAPI = async (params: GetInspectorsStatsParams) =>
  (await taxios.get<GetInspectorsStatsResponse>("/inspectors/stats/", { params })).data;
