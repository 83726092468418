import type { InspectorStat } from "src/apis/inspectors/stats/types";
import { toPercent } from "src/utils/toPercent";

import type { HeaderKey } from "../components/SortableTable";

const toLocaleString = (value: number | null) => value?.toLocaleString() ?? "";

export const headerKeyList: HeaderKey<InspectorStat>[] = [
  { label: "업체", key: "company_name" },
  { label: "상태", key: "status", formatter: (status) => (status === "active" ? "활동" : "활동중지") },
  { label: "이름", key: "name" },
  { label: "활동지역", key: "assigned_area" },
  { label: "업무시작", key: "start_date" },
  { label: "진단\n(대)", key: "inspected_cars_count", formatter: toLocaleString },
  { label: "거래\n(cohort, 대)", key: "cohort_traded_cars_count", formatter: toLocaleString },
  { label: "거래율\n(cohort, %)", key: "cohort_trade_rate", formatter: toPercent },
  { label: "거래\n(대)", key: "traded_cars_count", formatter: toLocaleString },
  { label: "검토완료\n(대)", key: "examination_completed_count", formatter: toLocaleString },
  { label: "과진단\n(대)", key: "over_inspected_count", formatter: toLocaleString },
  { label: "과진단\n골격", key: "over_inspected_frames_count", formatter: toLocaleString },
  { label: "과진단\n외판", key: "over_inspected_outer_panels_count", formatter: toLocaleString },
  { label: "오진단\n(대)", key: "under_inspected_count", formatter: toLocaleString },
  { label: "오진단\n골격", key: "under_inspected_frames_count", formatter: toLocaleString },
  { label: "오진단\n외판", key: "under_inspected_outer_panels_count", formatter: toLocaleString },
  { label: "클레임\n보상 (대)", key: "compensated_count", formatter: toLocaleString },
  { label: "과진단율\n(%)", key: "over_inspection_rate", formatter: toPercent },
  { label: "오진단율\n(%)", key: "under_inspection_rate", formatter: toPercent },
  { label: "클레임\n보상율 (%)", key: "compensation_rate", formatter: toPercent },
  { label: "등록증\n업로드 (대)", key: "registration_image_upload_count", formatter: toLocaleString },
  { label: "등록증\n업로드율 (%)", key: "registration_image_upload_rate", formatter: toPercent },
  { label: "CS 만족도\n(%)", key: "good_review_rate", formatter: toPercent },
  { label: "클레임 비용\n(만원)", key: "compensation_amount", formatter: toLocaleString },
];

export const exportHeaderKeyList: HeaderKey<InspectorStat>[] = [
  { label: "업체", key: "company_name" },
  { label: "상태", key: "status", formatter: (status) => (status === "active" ? "활동" : "활동중지") },
  { label: "이름", key: "name" },
  { label: "활동지역", key: "assigned_area" },
  { label: "업무시작", key: "start_date" },
  { label: "진단\n(대)", key: "inspected_cars_count" },
  { label: "거래\n(cohort, 대)", key: "cohort_traded_cars_count" },
  { label: "거래율\n(cohort)", key: "cohort_trade_rate" },
  { label: "거래\n(대)", key: "traded_cars_count" },
  { label: "검토완료\n(대)", key: "examination_completed_count" },
  { label: "과진단\n(대)", key: "over_inspected_count" },
  { label: "과진단\n골격", key: "over_inspected_frames_count" },
  { label: "과진단\n외판", key: "over_inspected_outer_panels_count" },
  { label: "오진단\n(대)", key: "under_inspected_count" },
  { label: "오진단\n골격", key: "under_inspected_frames_count" },
  { label: "오진단\n외판", key: "under_inspected_outer_panels_count" },
  { label: "클레임\n보상 (대)", key: "compensated_count" },
  { label: "과진단율", key: "over_inspection_rate" },
  { label: "오진단율", key: "under_inspection_rate" },
  { label: "클레임\n보상율", key: "compensation_rate" },
  { label: "등록증\n업로드 (대)", key: "registration_image_upload_count" },
  { label: "등록증\n업로드율", key: "registration_image_upload_rate" },
  { label: "CS 만족도", key: "good_review_rate" },
  { label: "클레임 비용\n(만원)", key: "compensation_amount" },
];
