import type { Dayjs } from "dayjs";
import dayjs, { extend, locale } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import "dayjs/locale/ko";

export const init = () => {
  locale("ko");
  extend(customParseFormat);
  extend(isBetween);
  extend(isSameOrAfter);
};

export const format = (date: Dayjs, format = "YYYY-MM-DD") => dayjs(date).format(format);
