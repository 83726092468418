import type { VFC } from "react";

import { Typography } from "@PRNDcompany/heydealer-ui";

type DataListItemProps = {
  label: string;
  value: string;
};

const DataListItem: VFC<DataListItemProps> = ({ label, value }) => (
  <div>
    <Typography variant="body_2" color="base_gray6">
      {label}
    </Typography>
    <Typography variant="body_2" preWrap>
      {value}
    </Typography>
  </div>
);

export default DataListItem;
