import type { FC } from "react";

import { css } from "@emotion/react";
import { HumanThumbnail, Typography } from "@PRNDcompany/heydealer-ui";

import type { Car } from "src/apis/cars/types";

type CarListItemHeaderProps = {
  car: Car;
};

const headerStyle = css`
  display: grid;
  justify-content: space-between;
  align-items: end;
  gap: 5rem;

  position: relative;

  width: 60rem;

  grid-template-columns: 1fr auto;
`;

const inspectorStyle = css`
  display: grid;
  justify-content: end;
  align-items: center;
  gap: 0.25rem;

  grid-auto-flow: column;
`;

const CarListItemHeader: FC<CarListItemHeaderProps> = ({
  car: {
    status_display,
    pre_inspection: {
      address,
      reserve_at,
      completed_at,
      inspector: { profile_image_url, name },
    },
  },
}) => (
  <header css={headerStyle}>
    <div css={{ display: "grid", gap: "0.5rem" }}>
      <Typography variant="subtitle_1">{status_display}</Typography>
      <div>
        <Typography variant="caption_1" color="base_gray6" preWrap>
          {address}
        </Typography>
        <Typography variant="caption_1" color="base_gray6">
          예약: {reserve_at}
        </Typography>
        {completed_at && (
          <Typography variant="caption_1" color="base_gray6">
            완료: {completed_at}
          </Typography>
        )}
      </div>
    </div>
    <div css={inspectorStyle}>
      <HumanThumbnail size={28} src={profile_image_url} />
      <Typography variant="body_2">{name} 평가사</Typography>
    </div>
  </header>
);

export default CarListItemHeader;
