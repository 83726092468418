import type { FC } from "react";
import React from "react";

import { useQuery } from "react-query";

import { createGetPostAPI } from "src/apis";
import { queryKeys } from "src/constants/queryKeys";

type PostDetailProps = {
  hashId: string;
};

const PostDetail: FC<PostDetailProps> = ({ hashId }) => {
  const { data } = useQuery({
    queryKey: queryKeys.posts.byHashId(hashId),
    queryFn: createGetPostAPI(hashId),
    suspense: true,
  });

  if (!data) {
    throw new Error("Suspense Error");
  }

  return <div dangerouslySetInnerHTML={{ __html: data }}></div>;
};

export default PostDetail;
