import type { ChangeEventHandler, FC, FormEventHandler } from "react";
import React, { useState } from "react";

import { useMutation, useQueryClient } from "react-query";

import { createPatchInspectorCommentAPI } from "src/apis";
import { queryKeys } from "src/constants/queryKeys";

import CommentForm from "../../CommentForm";

type CommentUpdateFormProps = {
  commentId: number;
  initialContent: string;
  onComplete: () => void;
};

const CommentUpdateForm: FC<CommentUpdateFormProps> = ({ commentId, initialContent, onComplete }) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: createPatchInspectorCommentAPI(commentId),
  });

  const [content, setContent] = useState(initialContent);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (content.trim() === "") {
      return;
    }

    await mutateAsync({ content });
    queryClient.invalidateQueries({
      queryKey: queryKeys.inspectorComments.byId(commentId),
    });

    onComplete();
  };

  const handleContentChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setContent(e.target.value);
  };

  return (
    <CommentForm content={content} onContentChange={handleContentChange} onSubmit={handleSubmit} loading={isLoading} />
  );
};

export default CommentUpdateForm;
