export type RouteGroupType = {
  label?: string;
  routes: RouteType[];
};

export type RouteType = {
  path: string;
  name: string;
  params?: Record<string, string>;
};

const routeGroups: RouteGroupType[] = [
  {
    label: "평가사",
    routes: [
      {
        path: "/inspectors/stats",
        name: "평가사 지표",
        params: {},
      },
      {
        path: "/inspectors",
        name: "평가사 현황",
        params: {},
      },
    ],
  },
  {
    label: "차량",
    routes: [
      {
        path: "/cars",
        name: "진단 차량",
      },
    ],
  },
  {
    routes: [
      {
        path: "/posts",
        name: "공지사항",
      },
    ],
  },
];

export default routeGroups;
