import type { VFC } from "react";
import { Fragment } from "react";

import { css } from "@emotion/react";
import { colors, Typography } from "@PRNDcompany/heydealer-ui";
import { useInfiniteQuery, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import type { GetCarsParams } from "src/apis";
import { getAccidentRepairsImageAPI, getCarsAPI } from "src/apis";
import { queryKeys } from "src/constants/queryKeys";
import { useInfiniteScrollRef } from "src/hooks/useInfiniteScrollRef";

import CarListItem from "./CarListItem";

const loaderWrapperStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 8rem;
`;

const carListStyle = css`
  display: grid;
  gap: 1.5rem;
`;

const CarList: VFC = () => {
  const [searchParams] = useSearchParams();

  const { data: accidentRepairsImage } = useQuery({
    queryKey: queryKeys.accidentRepairsImage(),
    queryFn: () => getAccidentRepairsImageAPI(searchParams),
  });

  const params: GetCarsParams = {
    inspector: searchParams.get("inspector") || undefined,
    car_number: searchParams.get("car_number") || undefined,
    status: searchParams.get("status") || undefined,
    examination_status: searchParams.get("examination_status") || undefined,
    is_over_inspected: searchParams.get("is_over_inspected")
      ? searchParams.get("is_over_inspected") === "true"
      : undefined,
    is_under_inspected: searchParams.get("is_under_inspected")
      ? searchParams.get("is_under_inspected") === "true"
      : undefined,
    is_compensated: searchParams.get("is_compensated") ? searchParams.get("is_compensated") === "true" : undefined,
    min_inspected_date: searchParams.get("min_inspected_date") || undefined,
    max_inspected_date: searchParams.get("max_inspected_date") || undefined,
    min_traded_date: searchParams.get("min_traded_date") || undefined,
    max_traded_date: searchParams.get("max_traded_date") || undefined,
  };

  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery({
    queryKey: queryKeys.inspectors.byParams(params),
    queryFn: async ({ pageParam }) => {
      return await getCarsAPI({ ...params, page: pageParam?.page || 1 });
    },
    getNextPageParam: (data) => {
      const rel = data.meta?.link?.rel("next")[0];
      if (!rel) {
        return undefined;
      }

      return { page: new URL(rel.uri).searchParams.get("page") };
    },
    keepPreviousData: false,
    cacheTime: 0,
  });

  const scrollEndMarkerRef = useInfiniteScrollRef(fetchNextPage, isFetching);

  const cars = data?.pages.flatMap(({ data }) => data) || [];

  return (
    <Fragment>
      <div css={carListStyle}>
        {cars.map((car) => (
          <CarListItem car={car} key={car.hash_id} accidentRepairsImage={accidentRepairsImage} />
        ))}
      </div>
      {isFetching || hasNextPage ? (
        <div css={loaderWrapperStyle} ref={scrollEndMarkerRef}>
          <ClipLoader color={colors.brand_primary} />
        </div>
      ) : (
        cars.length === 0 && (
          <div css={{ textAlign: "center", padding: "1rem" }}>
            <Typography variant="body_1">차량이 없습니다.</Typography>
          </div>
        )
      )}
    </Fragment>
  );
};

export default CarList;
