import type { FC } from "react";
import React from "react";

import type { Post } from "src/apis/posts/types";

import Accordion from "../../../components/Accordion";

import PostAccordionContent from "./PostAccordionContent";
import PostAccordionHeader from "./PostAccordionHeader";

type PostAccordionProps = {
  post: Post;
};

const PostAccordion: FC<PostAccordionProps> = ({ post }) => {
  return (
    <Accordion header={<PostAccordionHeader post={post} />}>
      <PostAccordionContent hashId={post.hash_id} />
    </Accordion>
  );
};

export default PostAccordion;
