import { css } from "@emotion/react";
import { colors } from "@PRNDcompany/heydealer-ui";

export const boxStyle = css`
  margin: -0.5px;

  border: 1px solid ${colors.base_gray3};

  background-color: ${colors.base_white};
`;
