import type { GetCarsParams, GetInspectorsParams, GetInspectorsStatsParams } from "src/apis";

// XXX-4433 params:string 타입 정의 필요
export const queryKeys = {
  authToken: () => ["authToken"],
  accidentRepairsImage: () => ["accidentRepairsImage"],
  cars: {
    all: () => ["cars"],
    byParams: (params: GetCarsParams) => [...queryKeys.cars.all(), params],
  },
  companies: () => ["companies"],
  inspectorComments: {
    byId: (id: number) => ["inspectorComments", id],
  },
  inspectors: {
    all: () => ["inspectors"],
    byHashId: {
      all: (hashId: string) => [...queryKeys.inspectors.all(), hashId],
      comments: (hashId: string) => [...queryKeys.inspectors.byHashId.all(hashId), "comments"],
    },
    byParams: (params: GetInspectorsParams) => [...queryKeys.inspectors.all(), params],
    names: () => [...queryKeys.inspectors.all(), "names"],
    stats: {
      all: () => [...queryKeys.inspectors.all(), "stats"],
      byParams: (params: GetInspectorsStatsParams) => [...queryKeys.inspectors.stats.all(), params],
    },
  },
  posts: {
    all: () => ["posts"],
    byHashId: (hashId: string) => [...queryKeys.posts.all(), hashId],
    list: () => [...queryKeys.posts.all(), "list"],
  },
};
