import type { FC } from "react";
import { useState } from "react";

import { Button, Typography, toast, SinglelineTextfield, Dropdown } from "@PRNDcompany/heydealer-ui";
import dayjs from "dayjs";
import { useMutation } from "react-query";

import type { PostInspectorPreInspectionScheduleRulesExclusionPayload } from "src/apis";
import { createPostInspectorPreInspectionScheduleRulesExclusionAPI } from "src/apis";
import type { Inspector } from "src/apis/inspectors/types";

import ExclusionRule from "./ExclusionRule";

type ExclusionRulesProps = {
  hashId: Inspector["hash_id"];
  exclusionRules: Inspector["exclusion_rules"];
  refetchInspector: () => Promise<void>;
};

const hourOptionList = [...Array(22 - 8 + 1)]
  .map((_, i) => i + 8)
  .map((value) => value.toString().padStart(2, "0"))
  .map((value) => ({ value, children: value }));

const minuteOptionList = [0, 30]
  .map((value) => value.toString().padStart(2, "0"))
  .map((value) => ({ value, children: value }));

const defaultPayload: PostInspectorPreInspectionScheduleRulesExclusionPayload = {
  exclude_from: "",
  exclude_until: "",
};

const ExclusionRules: FC<ExclusionRulesProps> = ({ hashId, exclusionRules, refetchInspector }) => {
  const [slicePastExcludeRecords, setSlicePastExcludeRecords] = useState(true);
  const { mutateAsync: createExclusion, isLoading } = useMutation(
    createPostInspectorPreInspectionScheduleRulesExclusionAPI(hashId)
  );

  const [payload, setPayload] = useState<PostInspectorPreInspectionScheduleRulesExclusionPayload>(defaultPayload);

  const onCreateClick = async () => {
    if (
      !dayjs(payload.exclude_from, "YYYY-MM-DD HH:mm").isValid() ||
      !dayjs(payload.exclude_until, "YYYY-MM-DD HH:mm").isValid()
    ) {
      toast.fail("휴무 일정을 올바르게 입력해주세요.");

      return;
    }

    await createExclusion(payload);
    await refetchInspector();
    toast.success("평가 불가능 날짜가 추가되었습니다.");
    setPayload(defaultPayload);
  };

  const updateDate = (key: keyof PostInspectorPreInspectionScheduleRulesExclusionPayload, date: string) => {
    const [, time = ""] = payload[key].split(" ");

    setPayload({ ...payload, [key]: `${date} ${time}` });
  };

  const updateHour = (key: keyof PostInspectorPreInspectionScheduleRulesExclusionPayload, hour: string) => {
    const [date, time = ""] = payload[key].split(" ");
    const [, minute] = time.split(":");

    setPayload({ ...payload, [key]: `${date} ${hour}:${minute}:00` });
  };

  const updateMinute = (key: keyof PostInspectorPreInspectionScheduleRulesExclusionPayload, minute: string) => {
    const [date, time = ""] = payload[key].split(" ");
    const [hour] = time.split(":");

    setPayload({ ...payload, [key]: `${date} ${hour}:${minute}:00` });
  };

  return (
    <div
      css={{
        display: "grid",
        gap: "2rem 0.5rem",
        gridTemplateColumns: "repeat(2, 50%)",
        alignItems: "start",
        padding: "0 1rem",
      }}
    >
      <div css={{ display: "grid", gap: "0.5rem" }}>
        <Typography variant="subtitle_2">예정</Typography>
        {exclusionRules.upcoming.map(({ id, exclude_from, exclude_until }) => (
          <ExclusionRule
            key={id}
            id={id}
            exclude_from={exclude_from}
            exclude_until={exclude_until}
            refetchInspector={refetchInspector}
          />
        ))}
      </div>
      <div css={{ display: "grid", gap: "0.5rem" }}>
        <Typography variant="subtitle_2">과거 기록</Typography>
        {exclusionRules.past
          .slice(0, slicePastExcludeRecords ? 2 : exclusionRules.past.length)
          .map(({ id, exclude_from, exclude_until }) => (
            <div key={id}>
              <Typography variant="body_1">
                - {dayjs(exclude_from).format("YYYY년 MM월 DD일(ddd) HH시 mm분")} ~&nbsp;
                {dayjs(exclude_until).format("YYYY년 MM월 DD일(ddd) HH시 mm분")}
              </Typography>
            </div>
          ))}
        {exclusionRules.past.length > 2 && (
          <div css={{ justifySelf: "end" }}>
            <Button
              variant="text"
              arrow={slicePastExcludeRecords ? "down" : "up"}
              color="gray"
              onClick={() => setSlicePastExcludeRecords((s) => !s)}
            >
              {slicePastExcludeRecords ? `전체 보기(${exclusionRules.past.length})` : "숨기기"}
            </Button>
          </div>
        )}
      </div>
      <div css={{ display: "grid", gap: "0.5rem", gridColumn: "1 / -1" }}>
        <Typography variant="subtitle_2">날짜 추가하기</Typography>
        <div
          css={{
            display: "grid",
            gap: "0.5rem",
            gridAutoFlow: "column",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <SinglelineTextfield
            size={48}
            value={payload.exclude_from.split(" ")[0]}
            onChange={(e) => updateDate("exclude_from", e.target.value)}
            type="date"
          />
          <Dropdown
            size={48}
            options={hourOptionList}
            value={payload.exclude_from.split(" ")[1]?.split(":")[0] ?? ""}
            onChange={(value) => updateHour("exclude_from", value)}
          />
          <Dropdown
            size={48}
            options={minuteOptionList}
            value={payload.exclude_from.split(" ")[1]?.split(":")[1] ?? ""}
            onChange={(value) => updateMinute("exclude_from", value)}
          />
          <Typography variant="body_1"> ~ </Typography>
          <SinglelineTextfield
            size={48}
            value={payload.exclude_until.split(" ")[0]}
            onChange={(e) => updateDate("exclude_until", e.target.value)}
            type="date"
          />
          <Dropdown
            size={48}
            options={hourOptionList}
            value={payload.exclude_until.split(" ")[1]?.split(":")[0] ?? ""}
            onChange={(value) => updateHour("exclude_until", value)}
          />
          <Dropdown
            size={48}
            options={minuteOptionList}
            value={payload.exclude_until.split(" ")[1]?.split(":")[1] ?? ""}
            onChange={(value) => updateMinute("exclude_until", value)}
          />
          <Button variant="text" onClick={onCreateClick} loading={isLoading}>
            추가
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ExclusionRules;
