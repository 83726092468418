import type { ChangeEventHandler, FC, ReactNode } from "react";
import { useState } from "react";

import { css } from "@emotion/react";
import { Button, Checkbox, SinglelineTextfield, Typography } from "@PRNDcompany/heydealer-ui";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

import type { GetInspectorsNamesResponse } from "src/apis";
import { getInspectorsNamesAPI } from "src/apis";
import type { CarStatus } from "src/apis/cars/types";
import { FilterForm, FilterRow } from "src/components/molecules/Filter";
import { queryKeys } from "src/constants/queryKeys";

type FilterGroupProps = {
  defaultParams: URLSearchParams;
  applyFilterParams: (filterParams: URLSearchParams) => void;
};

const statusArray: [CarStatus, string][] = [
  ["reserved", "진단예약완료"],
  ["ongoing", "거래진행중"],
  ["failed", "거래실패"],
  ["traded", "거래완료"],
];

const dateRangeWrapperStyle = css`
  display: grid;
  justify-content: start;
  align-items: center;
  gap: 1rem;

  grid-auto-flow: column;
`;

const formatInspector = (inspector: GetInspectorsNamesResponse[number]) => `${inspector.name} (${inspector.hash_id})`;

const FilterGroup: FC<FilterGroupProps> = ({ defaultParams, applyFilterParams }) => {
  const [searchParams] = useSearchParams();

  const { data: inspectors } = useQuery({
    queryKey: queryKeys.inspectors.names(),
    queryFn: async () => await getInspectorsNamesAPI(searchParams),
    suspense: true,
  });

  if (!inspectors) {
    throw new Error("Suspense Error");
  }

  const [selectedInspector, setSelectedInspector] = useState<GetInspectorsNamesResponse[number] | null>(
    inspectors.find((inspector) => inspector.hash_id === defaultParams.get("inspector")) || null
  );

  const [inspectorInput, setInspectorInput] = useState<string>(
    selectedInspector ? formatInspector(selectedInspector) : ""
  );

  const handleInspectorInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    setInspectorInput(value);

    const selectedInspector = inspectors.find((inspector) => formatInspector(inspector) === value);
    setSelectedInspector(selectedInspector || null);
  };

  return (
    <FilterForm defaultParams={defaultParams} applyFilterParams={applyFilterParams}>
      <FilterRow title="평가사">
        <div
          css={{ display: "grid", gridAutoFlow: "column", gap: "1rem", justifyContent: "start", alignItems: "center" }}
        >
          <SinglelineTextfield
            size={48}
            value={inspectorInput}
            onChange={handleInspectorInputChange}
            suggestions={
              inspectors
                ?.filter((inspector) => inspector.name.includes(inspectorInput))
                .map((inspector) => ({
                  value: `${inspector.name} (${inspector.hash_id})`,
                  displayValue: `${inspector.name} (${inspector.hash_id})`
                    .split(inspectorInput)
                    .reduce<ReactNode[]>((acc, cur, idx) => [...acc, <b key={idx}>{inspectorInput}</b>, cur], [])
                    .slice(1),
                })) ?? []
            }
            onBlur={() => {
              if (!selectedInspector) {
                setInspectorInput("");
              }
            }}
            width={240}
          />
          <Typography variant="caption_1" color="base_gray6">
            * 반드시 아래 목록에서 선택해주세요
          </Typography>
        </div>
        <input name="inspector" value={selectedInspector?.hash_id ?? ""} hidden />
      </FilterRow>
      <FilterRow title="차량번호">
        <SinglelineTextfield
          size={48}
          name="car_number"
          defaultValue={defaultParams.get("car_number") || ""}
          width={240}
        />
      </FilterRow>
      <FilterRow title="상태">
        <div css={{ display: "grid", gap: "3rem", justifyContent: "start", gridTemplateColumns: "repeat(4, auto)" }}>
          {statusArray.map(([status, label]) => (
            <Checkbox
              key={status}
              size={48}
              variant="list"
              label={label}
              name="status"
              value={status}
              defaultChecked={!!defaultParams.getAll("status")?.includes(status)}
              noSidePadding
            />
          ))}
        </div>
      </FilterRow>
      <FilterRow title="진단결과">
        <div
          css={{
            display: "grid",
            justifyContent: "start",
            gap: "0 3rem",
            gridTemplateColumns: "repeat(3, auto)",
          }}
        >
          <Checkbox
            variant="list"
            size={48}
            label="검토완료"
            name="examination_status"
            value="completed"
            defaultChecked={defaultParams.get("examination_status") === "completed"}
            noSidePadding
          />
          <Checkbox
            variant="list"
            size={48}
            label="과진단"
            name="is_over_inspected"
            value="true"
            defaultChecked={defaultParams.get("is_over_inspected") === "true"}
            noSidePadding
          />
          <Checkbox
            variant="list"
            size={48}
            label="오진단"
            name="is_under_inspected"
            value="true"
            defaultChecked={defaultParams.get("is_under_inspected") === "true"}
            noSidePadding
          />
          <Checkbox
            variant="list"
            size={48}
            label="클레임"
            name="is_compensated"
            value="true"
            defaultChecked={defaultParams.get("is_compensated") === "true"}
            noSidePadding
          />
        </div>
      </FilterRow>
      <FilterRow title="진단일">
        <div css={dateRangeWrapperStyle}>
          <SinglelineTextfield
            size={48}
            type="date"
            name="min_inspected_date"
            defaultValue={defaultParams.get("min_inspected_date") || ""}
            width={180}
          />
          <Typography variant="body_1"> ~ </Typography>
          <SinglelineTextfield
            size={48}
            type="date"
            name="max_inspected_date"
            defaultValue={defaultParams.get("max_inspected_date") || ""}
            width={180}
          />
        </div>
      </FilterRow>
      <FilterRow title="거래일">
        <div css={dateRangeWrapperStyle}>
          <SinglelineTextfield
            size={48}
            type="date"
            name="min_traded_date"
            defaultValue={defaultParams.get("min_traded_date") || ""}
            width={180}
          />
          <Typography variant="body_1"> ~ </Typography>
          <SinglelineTextfield
            size={48}
            type="date"
            name="max_traded_date"
            defaultValue={defaultParams.get("max_traded_date") || ""}
            width={180}
          />
        </div>
      </FilterRow>
      <Button size={48} customCSS={{ width: 120 }} type="submit">
        검색
      </Button>
    </FilterForm>
  );
};

export default FilterGroup;
