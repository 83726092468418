import taxios from "@PRNDcompany/taxios";

import type { ExclusionRule } from "../../../../types";
import type { Inspector } from "../../../types";

export type PostInspectorPreInspectionScheduleRulesExclusionPayload = Pick<
  ExclusionRule,
  "exclude_from" | "exclude_until"
>;

export const createPostInspectorPreInspectionScheduleRulesExclusionAPI =
  (hashId: Inspector["hash_id"]) => async (payload: PostInspectorPreInspectionScheduleRulesExclusionPayload) => {
    await taxios.post(`/inspectors/${hashId}/pre_inspection_schedule_rules/exclusion/`, payload);
  };
