import { QueryClient } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      queryFn: async ({ queryKey }) => {
        throw new Error(`QueryClient: queryFn not implemented for queryKey: ${queryKey}`);
      },
    },
  },
});

export default queryClient;
