import { colors } from "@PRNDcompany/heydealer-ui";

import type { InspectorStat } from "src/apis/inspectors/stats/types";

import type { SortableTableProps } from "../components/SortableTable";

export const getCustomCellStyle: SortableTableProps<InspectorStat>["getCustomCellStyle"] = (datum, key) => [
  key === "inspected_cars_count" && { background: datum.inspected_cars_count_color },
  key === "over_inspection_rate" && { background: datum.over_inspection_rate_color },
  datum.status === "inactive" && { background: colors.base_gray3 },
];

export const getIsCellValueClickable: SortableTableProps<InspectorStat>["getIsCellValueClickable"] = (datum, key) =>
  key === "inspected_cars_count" ||
  key === "cohort_traded_cars_count" ||
  key === "traded_cars_count" ||
  key === "examination_completed_count" ||
  key === "over_inspected_count" ||
  key === "under_inspected_count" ||
  key === "compensated_count";

export const getCellLink = (datum: InspectorStat, key: keyof InspectorStat, startDate: string, endDate: string) => {
  const { hash_id } = datum;
  const basePath = "/cars";

  const cellParamsMap: Partial<Record<keyof InspectorStat, string[][]>> = {
    inspected_cars_count: [
      ["inspector", hash_id],
      ["min_inspected_date", startDate],
      ["max_inspected_date", endDate],
      ["status", "ongoing"],
      ["status", "failed"],
      ["status", "traded"],
    ],
    cohort_traded_cars_count: [
      ["inspector", hash_id],
      ["min_inspected_date", startDate],
      ["max_inspected_date", endDate],
      ["status", "traded"],
    ],
    traded_cars_count: [
      ["inspector", hash_id],
      ["min_traded_date", startDate],
      ["max_traded_date", endDate],
      ["status", "traded"],
    ],
    examination_completed_count: [
      ["inspector", hash_id],
      ["min_traded_date", startDate],
      ["max_traded_date", endDate],
      ["status", "traded"],
      ["examination_status", "completed"],
    ],
    over_inspected_count: [
      ["inspector", hash_id],
      ["min_traded_date", startDate],
      ["max_traded_date", endDate],
      ["status", "traded"],
      ["examination_status", "completed"],
      ["is_over_inspected", "true"],
    ],
    under_inspected_count: [
      ["inspector", hash_id],
      ["min_traded_date", startDate],
      ["max_traded_date", endDate],
      ["status", "traded"],
      ["examination_status", "completed"],
      ["is_under_inspected", "true"],
    ],
    compensated_count: [
      ["inspector", hash_id],
      ["min_traded_date", startDate],
      ["max_traded_date", endDate],
      ["status", "traded"],
      ["is_compensated", "true"],
    ],
  };

  const cellParams = cellParamsMap[key];

  return cellParams && `${basePath}?${new URLSearchParams(cellParams).toString()}`;
};
