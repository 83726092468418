import type { FC } from "react";

import { css } from "@emotion/react";
import { colors, typographyStyles } from "@PRNDcompany/heydealer-ui";

import type { InspectorPerformance } from "src/apis/inspectors/types";
import { toPercent } from "src/utils/toPercent";

const tableStyle = css`
  width: fit-content;

  border-collapse: collapse;

  td,
  th {
    padding: 1rem;

    border: 1px solid ${colors.base_gray3};

    text-align: center;
    min-width: 70px;
  }

  th {
    background-color: ${colors.base_gray2};
    font-weight: normal;
  }
`;

type PerformanceTableProps = {
  performance: InspectorPerformance;
};

const PerformanceTable: FC<PerformanceTableProps> = ({ performance }) => {
  return (
    <table css={[typographyStyles.body_1, tableStyle]}>
      <tbody>
        <tr>
          <th>업무시작일</th>
          <td>{performance.start_date ?? "-"}</td>
          <th />
          <td />
          <th />
          <td />
        </tr>
        <tr>
          <th>누적 평가수</th>
          <td>{performance.inspected_cars_count}대</td>
          <th>최근 7일 평가수</th>
          <td>{performance.inspected_in_7_days_cars_count}대</td>
          <th>전월 일정 변경</th>
          <td>{performance.last_month_schedule_changes_count}</td>
        </tr>
        <tr>
          <th>누적 거래율</th>
          <td>{performance.trade_rate ? `${toPercent(performance.trade_rate)}%` : "-"}</td>
          <th>최근 14일 평가수</th>
          <td>{performance.inspected_in_14_days_cars_count}대</td>
          <th>전월 CS 만족도</th>
          <td>{toPercent(performance.last_month_good_review_rate)?.concat("%") ?? "-"}</td>
        </tr>
        <tr>
          <th>누적 클레임률</th>
          <td>{performance.claim_rate ? `${toPercent(performance.claim_rate)}%` : "-"}</td>
          <th>최근 100대 거래율</th>
          <td>{performance.recent_100_trade_rate ? `${toPercent(performance.recent_100_trade_rate)}%` : "-"}</td>
          <th />
          <td />
        </tr>
        <tr>
          <th>누적 평가취소</th>
          <td>{performance.cancelled_cars_count}대</td>
          <th>최근 100대 클레임률</th>
          <td>{performance.recent_100_claim_rate ? `${toPercent(performance.recent_100_claim_rate)}%` : "-"}</td>
          <th />
          <td />
        </tr>
      </tbody>
    </table>
  );
};

export default PerformanceTable;
