import { useQuery } from "react-query";

import { createGetInspectorCommentAPI } from "src/apis";
import type { InspectorComment } from "src/apis/types";
import { queryKeys } from "src/constants/queryKeys";

const useInspectorCommentQuery = (initialData: InspectorComment) => {
  const { data, ...rest } = useQuery({
    queryKey: queryKeys.inspectorComments.byId(initialData.id),
    queryFn: createGetInspectorCommentAPI(initialData.id),
    staleTime: Infinity,
    initialData,
  });

  if (!data) {
    throw new Error("data is required");
  }

  return { data, ...rest };
};

export default useInspectorCommentQuery;
