import type { FC } from "react";

import { Global } from "@emotion/react";
import { Configure, GlobalStyle, Toaster } from "@PRNDcompany/heydealer-ui";
import dayjs from "dayjs";
import { QueryClientProvider } from "react-query";
import { Navigate, Route, Routes as _Routes } from "react-router-dom";

import globalStyle from "src/globalStyle";
import { format, init as initDayjs } from "src/modules/dayjs";
import queryClient from "src/modules/queryClient";
import { init as initTAxios, setBaseURL } from "src/modules/taxios";
import Cars from "src/routes/cars";
import Inspectors from "src/routes/inspectors";
import Stats from "src/routes/inspectors/stats";

import Layout from "./routes/layout";
import Posts from "./routes/posts";

const fallbackURL = `/inspectors/stats?start_date=${format(dayjs().subtract(1, "month"))}&end_date=${format(dayjs())}`;

initDayjs();
initTAxios();

const Routes: FC = () => {
  return (
    <_Routes>
      <Route element={<Layout />}>
        <Route path="/cars" element={<Cars />} />
        <Route path="/posts" element={<Posts />} />
        <Route path="inspectors">
          <Route path="" element={<Inspectors />} />
          <Route path="stats" element={<Stats />} />
        </Route>
        <Route path="/" element={<Navigate to={fallbackURL} replace />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </_Routes>
  );
};

const App: FC = () => (
  <QueryClientProvider client={queryClient}>
    <Global styles={globalStyle} />
    <GlobalStyle />
    <Routes />
    <Toaster position={["bottom", "right"]} />
    <Configure defaultBaseURL={process.env.REACT_APP_BASE_URL} onBaseURLChange={setBaseURL} />
  </QueryClientProvider>
);

export default App;
