import { useCallbackRef } from "./useCallbackRef";

export const useInfiniteScrollRef = (load: () => unknown, loading?: boolean) => {
  return useCallbackRef(
    (scrollEndMarker: HTMLDivElement) => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting && !loading) {
            load();
          }
        },
        { threshold: 0 }
      );

      observer.observe(scrollEndMarker);

      return () => {
        observer.unobserve(scrollEndMarker);
      };
    },
    [load, loading]
  );
};
