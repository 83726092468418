import { toast } from "@PRNDcompany/heydealer-ui";
import taxios, { initTAxiosResponseInterceptor } from "@PRNDcompany/taxios";

import { setAuthToken } from "./auth";

const createToast = (message: string, type: "default" | "success" | "error") =>
  ({
    default: (message: string) => toast.default(message),
    success: (message: string) => toast.success(message),
    error: (message: string) => toast.fail(message),
  }[type](message));

const requestInterceptors: number[] = [];
const responseInterceptors: number[] = [];

export const init = () => {
  requestInterceptors.map((interceptorId) => taxios.interceptors.request.eject(interceptorId));
  responseInterceptors.map((interceptorId) => taxios.interceptors.response.eject(interceptorId));

  requestInterceptors.length = 0;
  responseInterceptors.length = 0;

  setBaseURL(process.env.REACT_APP_BASE_URL);

  requestInterceptors.push(
    taxios.interceptors.request.use((request) => {
      const token = localStorage.getItem("token");
      if (!token) {
        return request;
      }

      if (!request.headers) {
        throw new Error("Axios Error");
      }

      request.headers["Authorization"] = `JWT ${token}`;

      return request;
    })
  );

  responseInterceptors.push(initTAxiosResponseInterceptor(createToast));
  responseInterceptors.push(
    taxios.interceptors.response.use(
      (res) => res,
      (err) => {
        if (err.response?.status === 401) {
          setAuthToken("");
        }

        return Promise.reject(err);
      }
    )
  );
};

export const setBaseURL = (baseURL?: string) => {
  taxios.defaults.baseURL = baseURL;
};
