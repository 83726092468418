import type { FC } from "react";
import React from "react";

import { css } from "@emotion/react";
import { ArrowDownIcon, ArrowUpIcon, Typography } from "@PRNDcompany/heydealer-ui";

import { useAccordionContext } from "src/components/Accordion";

const wrapperStyle = css`
  display: flex;
  justify-content: space-between;

  padding: 1.25rem 1rem;

  cursor: pointer;
`;

const CommentListAccordionHeader: FC = () => {
  const { isOpen } = useAccordionContext();

  return (
    <div css={wrapperStyle}>
      <Typography variant="subtitle_3">코멘트 {isOpen ? "접기" : "펼치기"}</Typography>
      {isOpen ? <ArrowUpIcon size={24} /> : <ArrowDownIcon size={24} />}
    </div>
  );
};

export default CommentListAccordionHeader;
