import { css } from "@emotion/react";
import { colors } from "@PRNDcompany/heydealer-ui";

export const wrapperStyle = css`
  display: grid;

  padding: 0.5rem 0;

  background-color: ${colors.base_gray2};
`;
