import type { FC } from "react";

import { css } from "@emotion/react";
import { useQuery } from "react-query";

import { getInspectorsStatsAPI } from "src/apis";
import type { InspectorStat } from "src/apis/inspectors/stats/types";
import { queryKeys } from "src/constants/queryKeys";

import ExcelExportButton from "../components/ExcelExportButton";
import type { SortableTableProps } from "../components/SortableTable";
import SortableTable from "../components/SortableTable";
import { useInspectorsStatsParams } from "../hooks/useInspectorsStatsParams";

import { exportHeaderKeyList, headerKeyList } from "./constants";
import { getCellLink, getCustomCellStyle, getIsCellValueClickable } from "./utils";

const wrapperStyle = css`
  display: grid;
  grid-gap: 1rem;

  align-content: start;
`;

const StatsSortableTable: FC = () => {
  const inspectorsStatsParams = useInspectorsStatsParams();
  const { start_date, end_date } = inspectorsStatsParams;

  const { data, isLoading } = useQuery({
    queryKey: queryKeys.inspectors.stats.byParams(inspectorsStatsParams),
    queryFn: () => getInspectorsStatsAPI(inspectorsStatsParams),
  });

  const onCellValueClick: SortableTableProps<InspectorStat>["onCellValueClick"] = (datum, key) => {
    const link = getCellLink(datum, key, start_date || "", end_date);

    if (link) {
      window.open(window.location.origin.concat(link));
    }
  };

  return (
    <div css={wrapperStyle}>
      <div css={{ justifySelf: "self-end", display: "flex", justifyContent: "flex-end", gap: "0.5rem" }}>
        <ExcelExportButton headerKeys={exportHeaderKeyList} data={data} filename="평가사_지표">
          엑셀로 내보내기
        </ExcelExportButton>
      </div>
      <div css={{ overflow: "auto" }}>
        <SortableTable
          headerKeys={headerKeyList}
          data={data}
          loading={isLoading}
          defaultSort={{ key: "name", ascending: true }}
          fixedColumnKey={["name"]}
          getCustomCellStyle={getCustomCellStyle}
          getIsCellValueClickable={getIsCellValueClickable}
          onCellValueClick={onCellValueClick}
        />
      </div>
    </div>
  );
};

export default StatsSortableTable;
