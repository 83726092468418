import type { FC, ReactNode } from "react";
import { useState, createContext, useContext } from "react";

import { animated, useSpring } from "@react-spring/web";

import { useCallbackRef } from "src/hooks/useCallbackRef";

type AccordionProps = {
  header: ReactNode;
  children: ReactNode;
};

type AccordionContext = {
  isOpen: boolean;
  toggleOpen: () => void;
};

const AccordionContext = createContext<AccordionContext>({ isOpen: false, toggleOpen: () => null });

export const useAccordionContext = () => useContext(AccordionContext);

const Accordion: FC<AccordionProps> = ({ header, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const { height } = useSpring({ height: isOpen ? contentHeight : 0 });

  const resizeObserver = new ResizeObserver((entries) => {
    const { height } = entries[0].contentRect;
    setContentHeight(height);
  });

  const contentAreaRef = useCallbackRef<HTMLDivElement>((contentArea) => {
    resizeObserver.observe(contentArea);

    return () => resizeObserver.disconnect();
  }, []);

  const handleHeaderClick = () => setIsOpen(!isOpen);

  return (
    <AccordionContext.Provider value={{ isOpen, toggleOpen: handleHeaderClick }}>
      <div css={{ width: "100%" }}>
        <div onClick={handleHeaderClick}>{header}</div>
        <animated.div css={{ overflow: "hidden" }} style={{ height }}>
          <div ref={contentAreaRef}>{children}</div>
        </animated.div>
      </div>
    </AccordionContext.Provider>
  );
};

export default Accordion;
