import type { FC } from "react";
import React from "react";

import { Outlet } from "react-router-dom";

import { useAuthToken } from "src/modules/auth";

import Login from "./Login";

const Layout: FC = () => {
  const token = useAuthToken();

  if (!token) {
    return <Login />;
  }

  return <Outlet />;
};

export default Layout;
