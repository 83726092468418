import type { VFC } from "react";

import { css } from "@emotion/react";
import { colors, HeydealerLogo } from "@PRNDcompany/heydealer-ui";

import MainBackground from "src/assets/main-background.jpg";
import LoginForm from "src/components/organisms/LoginForm";

const loginFormWrapperStyle = css`
  display: grid;

  position: fixed;

  width: 100%;
  height: 100%;

  background: linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%),
    url(${MainBackground}) center / cover no-repeat;

  z-index: 1;
  place-items: center;
`;

const Login: VFC = () => (
  <div css={loginFormWrapperStyle}>
    <div css={{ display: "grid", gap: "1rem", justifyItems: "center" }}>
      <HeydealerLogo css={{ color: colors.base_white }} />
      <LoginForm />
    </div>
  </div>
);

export default Login;
