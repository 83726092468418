import type { FC } from "react";
import React, { useState } from "react";

import { Button, Typography } from "@PRNDcompany/heydealer-ui";

import type { InspectorComment } from "src/apis/types";

import CommentDeleteButton from "./components/CommentDeleteButton";
import CommentUpdateForm from "./components/CommentUpdateForm";
import useInspectorCommentQuery from "./hooks/useInspectorCommentQuery";
import { buttonWrapperStyle, wrapperStyle } from "./styles";

type CommentProps = {
  inspectorHashId: string;
  initialData: InspectorComment;
  onChangeSubmit?: () => void;
};

const Comment: FC<CommentProps> = ({ inspectorHashId, initialData }) => {
  const { data } = useInspectorCommentQuery(initialData);

  const [isHover, setIsHover] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div css={wrapperStyle} onMouseOver={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      <Typography variant="caption_1" color="base_gray5">
        {data.created_at}
      </Typography>
      {isEditing ? (
        <CommentUpdateForm commentId={data.id} initialContent={data.content} onComplete={() => setIsEditing(false)} />
      ) : (
        <div css={{ display: "grid", gap: "1rem", gridTemplateColumns: "1fr 7.5rem", alignItems: "center" }}>
          <Typography variant="caption_1">{data.content}</Typography>
          {isHover ? (
            <div css={buttonWrapperStyle}>
              <CommentDeleteButton inspectorHashId={inspectorHashId} commentId={data.id} />
              <Button variant="tiny" size={32} color="blue" onClick={() => setIsEditing(true)}>
                수정
              </Button>
            </div>
          ) : (
            data.author_username && (
              <Typography variant="caption_1" color="base_gray5" customCSS={{ textAlign: "right" }}>
                by {data.author_username}
              </Typography>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default Comment;
