import type { MouseEventHandler } from "react";

import type { Interpolation, Theme } from "@emotion/react";
import { css } from "@emotion/react";
import { Typography, colors } from "@PRNDcompany/heydealer-ui";

type SortableThProps<TData extends Record<string, unknown>> = {
  header: string;
  dataKey: keyof TData;
  selected: boolean;
  ascending: boolean;
  cellStyle?: Interpolation<Theme>;
  fixed?: boolean;
  onClick: MouseEventHandler<HTMLTableCellElement>;
};

const thStyle = css`
  position: relative;

  background: ${colors.base_gray3};

  border-top: 1px solid ${colors.base_gray4};

  z-index: 2;

  cursor: pointer;
  user-select: none;

  @media (hover: hover) {
    :hover {
      background: ${colors.base_gray2};
    }
  }
`;

const selectedThStyle = css`
  background: ${colors.base_gray4};
`;

const ascIndicatorStyle = css`
  position: absolute;
  top: calc(50% - 0.2rem);
  right: 0.3rem;

  box-sizing: border-box;
  width: 0;
  height: 0;

  border-bottom: 0.4rem solid ${colors.base_dark};
  border-left: 0.2rem solid transparent;
  border-right: 0.2rem solid transparent;
`;

const fixedStyle = css`
  position: sticky;
  left: 0;
  right: 0;

  z-index: 3;
`;

const SortableTh = <TData extends Record<string, unknown>>({
  header,
  dataKey,
  selected,
  ascending,
  cellStyle,
  fixed,
  onClick,
}: SortableThProps<TData>) => (
  <th css={[cellStyle, thStyle, selected && selectedThStyle, fixed && fixedStyle]} onClick={onClick} data-key={dataKey}>
    <Typography variant="subtitle_4">{header}</Typography>
    {selected && <div css={[ascIndicatorStyle, !ascending && { transform: "rotate(180deg)" }]} />}
  </th>
);

export default SortableTh;
