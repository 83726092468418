import taxios from "@PRNDcompany/taxios";

import type { PaginatedResponse } from "src/utils/parsePaginatedResponse";
import { parsePaginatedResponse } from "src/utils/parsePaginatedResponse";

import type { Inspector } from "./types";

export type GetInspectorsParams = {
  name?: string;
  company_id?: string;
  status?: string;
};

type GetInspectorsResponse = PaginatedResponse<Inspector[]>;

export const getInspectorsAPI = async (
  params: GetInspectorsParams & { page?: number; meta_only?: boolean }
): Promise<GetInspectorsResponse> => {
  return parsePaginatedResponse(await taxios.get<Inspector[]>("/inspectors/", { params }));
};
