import type { FC } from "react";

import { css } from "@emotion/react";
import { colors, Typography } from "@PRNDcompany/heydealer-ui";
import type { InfiniteData } from "react-query";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import type { GetInspectorsParams } from "src/apis";
import { getInspectorsAPI } from "src/apis";
import type { Inspector } from "src/apis/inspectors/types";
import { queryKeys } from "src/constants/queryKeys";
import { useInfiniteScrollRef } from "src/hooks/useInfiniteScrollRef";
import type { PaginatedResponse } from "src/utils/parsePaginatedResponse";

import InspectorItem from "./InspectorListItem";

const loaderWrapperStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 8rem;
`;

const inspectorListStyle = css`
  display: grid;
  gap: 1.5rem;
`;

const InspectorList: FC = () => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const params: GetInspectorsParams = {
    name: searchParams.get("name") || undefined,
    company_id: searchParams.get("company_id") || undefined,
    status: searchParams.get("status") || undefined,
  };

  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery({
    queryKey: queryKeys.inspectors.byParams(params),
    queryFn: async ({ pageParam }) => {
      return await getInspectorsAPI({ ...params, page: pageParam?.page || 1 });
    },
    getNextPageParam: (data) => {
      const rel = data.meta?.link?.rel("next")[0];
      if (!rel) {
        return undefined;
      }

      return { page: new URL(rel.uri).searchParams.get("page") };
    },
    keepPreviousData: false,
    cacheTime: 0,
  });

  const scrollEndMarkerRef = useInfiniteScrollRef(fetchNextPage, isFetching);

  const updateInspector = (hashId: Inspector["hash_id"], inspector: Inspector | null) => {
    queryClient.setQueryData<InfiniteData<PaginatedResponse<Inspector[]>> | undefined>(
      queryKeys.inspectors.byParams(params),
      (prevData) => {
        if (!prevData) {
          return undefined;
        }

        return {
          ...prevData,
          pages: prevData.pages.map((page) => ({
            ...page,
            data: page.data.map((item) => (item.hash_id === hashId ? inspector || item : item)),
          })),
        };
      }
    );
  };

  const inspectors = data?.pages.flatMap(({ data }) => data) || [];

  return (
    <div>
      <div css={inspectorListStyle}>
        {inspectors.map((inspector) => (
          <InspectorItem inspector={inspector} key={inspector.hash_id} updateInspector={updateInspector} />
        ))}
      </div>
      {isFetching || hasNextPage ? (
        <div css={loaderWrapperStyle} ref={scrollEndMarkerRef}>
          <ClipLoader color={colors.brand_primary} />
        </div>
      ) : (
        inspectors.length === 0 && (
          <div css={{ textAlign: "center", padding: "1rem" }}>
            <Typography variant="body_1">검색 결과에 일치하는 평가사가 없습니다.</Typography>
          </div>
        )
      )}
    </div>
  );
};

export default InspectorList;
