import type { FC } from "react";
import { Fragment } from "react";

import { css } from "@emotion/react";
import { Divider, Typography, colors } from "@PRNDcompany/heydealer-ui";

import routeGroups from "src/constants/routes";

import SidebarGroup from "./SidebarGroup";

const sidebarStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 15rem;
  height: 100%;

  background: ${colors.base_gray3};

  flex-shrink: 0;
`;

const ulStyle = css`
  margin: 0;

  padding: 0.5rem 0;
`;

const sidebarCaptionStyle = css`
  padding: 1rem;

  text-align: center;
`;

const Sidebar: FC = () => (
  <aside css={sidebarStyle}>
    <ul css={ulStyle}>
      {routeGroups.map((group, idx) => (
        <Fragment key={idx}>
          <SidebarGroup {...group} />
          <Divider color="base_gray5" />
        </Fragment>
      ))}
    </ul>
    <div css={sidebarCaptionStyle}>
      <Typography variant="subtitle_4" color="base_gray5">
        (주) 피알앤디컴퍼니
      </Typography>
    </div>
  </aside>
);

export default Sidebar;
